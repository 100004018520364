import type {
    MatrixAvailableDeliveryDate,
    MatrixConcreteProduct,
    MatrixData
} from '~/composables/types/api/searchDiscover/getMatrix';
import type {
    CellLockEvaluationData,
    CheckBlocked,
    CheckCartClosed,
    CheckChanneling,
    CheckNosBlocked,
    CheckWritable,
    MatrixCell
} from '~/composables/types/matrix/useMatrixContentDataTypes';
import type { BranchCluster } from '~/composables/types/api/searchDiscover/getBranchClusters';

const getCellWritable = (matrixData: MatrixData, product: MatrixConcreteProduct, branch: string): boolean => {
    const writable = matrixData.items.nosItems?.find(elem => elem.gtin === product.gtin && elem.branchId === branch)?.writable;

    return typeof writable === 'undefined' ? true : Boolean(writable);
};

export default function useMatrixBlockedCellEvaluationHelper(
    matrixData: MatrixData,
    getAllAvailableDeliveryDatesByGtin: (gtin: string, valid: boolean) => MatrixAvailableDeliveryDate[]
) {
    const { $can, $hasRole } = useNuxtApp();
    const currentTimestamp = new Date().getTime();

    const checkCartClosed = (cartClosed: boolean, nosTabSelected: boolean): CheckCartClosed => (cartClosed && !nosTabSelected ?
        { blocked: true, cartClosed: true, tooltip: 'matrix_locked_cart_closed' } :
        { blocked: false, cartClosed: false, tooltip: '' });

    const checkNosBlocked = (labels: string[], nosTabSelected: boolean): CheckNosBlocked => (
        !labels.includes('NOS') && !labels.includes('SAISON') && nosTabSelected ?
            { nosBlocked: true, tooltip: 'matrix_locked_nos' } :
            { nosBlocked: false, tooltip: '' });

    const checkWritable = (cell: MatrixCell): CheckWritable => (!cell.writable ?
        { writable: false, tooltip: 'matrix_locked_not_writable', nosBlocked: true } :
        { writable: true, tooltip: '', nosBlocked: false });

    const checkChanneling = (
        product: MatrixConcreteProduct,
        branch: string,
        branchCluster: BranchCluster | undefined,
        validColorByModules: boolean
    ): CheckChanneling => {
        if ($hasRole(RoleTypes.SUPPLIER)) {
            return { blocked: false, tooltip: '' };
        }

        const { customerGroupAccess, customerGroupBranchAccess } = product;
        const branchAccess = branchCluster ?
            branchCluster.branches.every(clusterBranch => customerGroupBranchAccess[clusterBranch]) :
            customerGroupBranchAccess[branch];

        if ((!customerGroupAccess || !branchAccess) && !validColorByModules) {
            return { blocked: true, tooltip: 'matrix_locked_channeling' };
        }

        return { blocked: false, tooltip: '' };
    };

    const checkSupplierRole = (): CheckBlocked => ($hasRole(RoleTypes.SUPPLIER) ?
        { blocked: true, tooltip: 'matrix_locked_supplier' } :
        { blocked: false, tooltip: '' });

    const checkOrderPermission = (): CheckBlocked => (!$can(PermissionTypes.ORDER) ?
        { blocked: true, tooltip: 'matrix_can_not_order' } :
        { blocked: false, tooltip: '' });

    const checkDeliveryDates = (
        availableDeliveryDatesByGtin: MatrixAvailableDeliveryDate[],
    ): CheckBlocked => (availableDeliveryDatesByGtin.length === 0 ?
        { blocked: true, tooltip: 'matrix_locked_delivery' } :
        { blocked: false, tooltip: '' }
    );

    const checkDeliveryDatesValidOnly = (
        availableDeliveryDatesByGtin: MatrixAvailableDeliveryDate[],
        date: string, nosTabSelected: boolean,
        gtinHasQuantities: boolean
    ): CheckBlocked => (!availableDeliveryDatesByGtin.some(
        deliveryDate => date >= deliveryDate.fromDate && date <= deliveryDate.toDate
    ) && !nosTabSelected && !gtinHasQuantities ?
        { blocked: true, tooltip: 'matrix_locked_delivery' } :
        { blocked: false, tooltip: '' });

    const checkFutureOrder = (
        product: MatrixConcreteProduct
    ): CheckBlocked => (product.orderFrom && currentTimestamp < strToDate(product.orderFrom).getTime() ?
        { blocked: true, tooltip: ['matrix_locked_future', { date: product.orderFrom }] } :
        { blocked: false, tooltip: '' });

    const blockedCellEvaluations = (cellData: CellLockEvaluationData, branchClusters: BranchCluster[] | undefined): MatrixCell => {
        const { branch, color, size, date, nosTabSelected } = cellData;
        const product: MatrixConcreteProduct = matrixData?.concreteProductsBySizeAndColor?.[size]?.[color];

        if (!product) {
            return {
                colorKey: '',
                branchId: branch,
                gtin: '',
                purchasePrice: undefined,
                sellPrice: undefined,
                packagingUnit: 1,
                labels: [],
                scalePrices: undefined,
                nosBlocked: true,
                blocked: true,
                tooltip: 'matrix_locked_fallback',
                writable: true,
                cartClosed: false,
                branchCluster: undefined
            };
        }

        const branchCluster = branchClusters?.find(cluster => cluster.name === branch) || undefined;

        const cell: MatrixCell = {
            colorKey: product.colorKey,
            branchId: branch,
            gtin: product.gtin,
            purchasePrice: product.purchasePrice,
            sellPrice: product.sellPrice,
            packagingUnit: product.packagingUnit,
            labels: product.labels,
            scalePrices: product.scalePriceList ?? null,
            blocked: false,
            nosBlocked: false,
            cartClosed: false,
            tooltip: '',
            writable: getCellWritable(matrixData, product, branch),
            branchCluster: branchCluster
        };

        const validModelKeys = [...new Set(
            matrixData.items.moduleItems
                .map(item => item?.colorKey?.toString())
                .filter(colorKey => typeof colorKey === 'string')
        )];
        const validOrderKeys = [...new Set(
            matrixData.items.orderItems
                .map(item => item?.colorKey?.toString())
                .filter(colorKey => typeof colorKey === 'string')
        )];
        const colorValidModule = Boolean(validModelKeys.length && validModelKeys.includes(cell.colorKey));
        const colorHasItems = Boolean(validOrderKeys.length && validOrderKeys.includes(cell.colorKey));

        const gtinHasQuantities = matrixData.items.orderItems.some(
            item => item.gtin === cell.gtin && item.deliveryDate === cellData.date && item.quantity > 0 && cellData.branch === item.branchId
        );

        const checkResults = {
            cartClosed: checkCartClosed(matrixData.items.cartClosed, nosTabSelected),
            channeling: checkChanneling(
                product,
                branch,
                branchCluster,
                colorValidModule || colorHasItems
            ),
            deliveryDates: checkDeliveryDates(getAllAvailableDeliveryDatesByGtin(product.gtin, false)),
            deliveryDatesValidOnly: checkDeliveryDatesValidOnly(
                getAllAvailableDeliveryDatesByGtin(product.gtin, true),
                date,
                nosTabSelected,
                gtinHasQuantities
            ),
            nosBlocked: checkNosBlocked(cell.labels, nosTabSelected),
            writable: checkWritable(cell),
            futureOrder: checkFutureOrder(product)
        };

        const blockedResults = [
            checkResults.cartClosed,
            checkResults.channeling,
            checkResults.deliveryDates,
            checkResults.deliveryDatesValidOnly,
            checkResults.futureOrder
        ];

        const blockedNosResults = [
            checkResults.writable,
            checkResults.nosBlocked,
        ];

        cell.blocked = blockedResults.some(result => result.blocked);
        cell.cartClosed = checkResults.cartClosed.cartClosed;
        cell.nosBlocked = checkResults.nosBlocked.nosBlocked;
        cell.writable = checkResults.writable.writable;
        cell.tooltip = [...blockedResults, ...blockedNosResults]
            .map(result => result.tooltip)
            .filter(Boolean);

        return cell;
    };

    return {
        checkCartClosed,
        checkNosBlocked,
        checkWritable,
        checkChanneling,
        checkSupplierRole,
        checkOrderPermission,
        checkDeliveryDates,
        checkDeliveryDatesValidOnly,
        checkFutureOrder,
        blockedCellEvaluations
    };
}
