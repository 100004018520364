import getBranchClusters from '~/composables/api/searchDiscover/getBranchClusters';
import type { BranchClusters } from '~/composables/types/api/searchDiscover/getBranchClusters';
import { useBranchClusterStore } from '~/composables/stores/useBranchClusterStore';

export default function useBranchClusters(abstractIdOrSku: string | null) {
    const { $t } = useNuxtApp();
    const branchClusterData: Ref<BranchClusters | null> = ref(null);
    const toasts = useToasts();
    const {
        update: updateBranchCluster,
        getClusterState,
        setClusterState,
        setClusterLoadingState,
    } = useBranchClusterStore();

    const loadBranchClusters = async(initialLoad: boolean): Promise<boolean> => {
        if (abstractIdOrSku) {
            setClusterLoadingState(true);
            const branchClusterResponse = await getBranchClusters(abstractIdOrSku);
            setClusterLoadingState(false);

            if (branchClusterResponse.state !== 'success') {
                if (branchClusterResponse.status && branchClusterResponse.status >= ResponseStatusTypes.SERVER_ERROR) {
                    toasts.add('LOAD_ERROR');
                }

                if (!initialLoad && branchClusterResponse.status === ResponseStatusTypes.NOT_FOUND) {
                    toasts.add({
                        type: 'warning',
                        headline: $t('Matrix.branch_cluster_toast_headline'),
                        text: $t('Matrix.branch_cluster_toast'),
                    });
                    branchClusterData.value = null;
                }

                return false;
            }

            branchClusterResponse.resetData = false;
            branchClusterData.value = branchClusterResponse;

            return true;
        }

        return false;
    };

    const updateBranchClusterById = (clusterId: number | null, resetData: boolean, initialClusterSetup: boolean) => {
        const clusterTemplate = branchClusterData.value?.branchClusters?.find(
            branchCluster => branchCluster.branchClusterId === clusterId
        ) ?? null;

        if (clusterTemplate) {
            clusterTemplate.resetData = resetData;
            clusterTemplate.initialClusterSetup = initialClusterSetup;
        }

        updateBranchCluster(clusterTemplate);
    };

    return {
        branchClusterData,
        updateBranchClusterById,
        loadBranchClusters,
        getClusterState,
        setClusterState,
    };
}
