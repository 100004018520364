<template>
    <div>
        <AtomAccordion v-model:open="accordionOpen" :title="$t('Matrix.matrix_order_fields_header')">
            <template #headerContent>
                <AtomNotification
                    v-if="!validForm && !nosTabSelected"
                    type="warning"
                    :text="$t('Matrix.additional_fields_invalid_label', Object.keys(fieldValues).filter((x: string) => !fieldIsValid(x)).length)"
                />
            </template>
            <template #innerContent>
                <div class="flex">
                    <div v-if="groups && Object.keys(groups).length && Object.keys(fieldValues).length" class="flex-1 fields">
                        <template v-for="(group, key) in groups" :key="key">
                            <h3>{{ group.name }}</h3>
                            <div class="field-container">
                                <div v-for="field in group.fields" :key="field.id" class="fieldHolder">
                                    <div v-if="field.type === 'select'">
                                        <p :class="[{'required-label': field.required}, 'order-field-name']">{{ field.name }}</p>
                                        <AtomSelect
                                            :options="adjustSelect(field.selectOptions)"
                                            :model-value="getFieldValueOfId(field)"
                                            :error-message="getErrorMessage(field)"
                                            @update:model-value="updateField(field, $event)"
                                        />
                                    </div>
                                    <div v-else-if="field.type === 'float'">
                                        <p :class="[{'required-label': field.required}, 'order-field-name']">{{ field.name }}</p>
                                        <AtomNumberInput
                                            :delay="250"
                                            :step="field.exactness === 0 ? 1 : getExactness(field.exactness)"
                                            :precision="field.exactness"
                                            :max-length="field.size"
                                            size="l"
                                            :min="0.0"
                                            :max="getMaxValue(field.size, field.exactness)"
                                            :allow-undefined="true"
                                            :allow-zero="true"
                                            :model-value="!isNaN(Number(getFieldValueOfId(field))) && getFieldValueOfId(field) !== ''
                                                ? Number(getFieldValueOfId(field))
                                                : undefined"
                                            :error-message="getErrorMessage(field)"
                                            @update:model-value="updateField(field, $event)"
                                        />
                                    </div>
                                    <div v-else-if="field.type === 'int'">
                                        <p :class="[{'required-label': field.required}, 'order-field-name']">{{ field.name }}</p>
                                        <AtomNumberInput
                                            :delay="250"
                                            :step="1"
                                            size="l"
                                            :min="0.0"
                                            :max="getMaxValue(field.size, 0)"
                                            :max-length="field.size"
                                            :allow-undefined="true"
                                            :allow-zero="true"
                                            :model-value="!isNaN(Number(getFieldValueOfId(field))) && getFieldValueOfId(field) !== ''
                                                ? Number(getFieldValueOfId(field))
                                                : undefined"
                                            :error-message="getErrorMessage(field)"
                                            @update:model-value="updateField(field, $event)"
                                        />
                                    </div>
                                    <div v-else-if="field.type === 'boolean'">
                                        <p :class="[{'required-label': field.required}, 'order-field-name']">{{ field.name }}</p>
                                        <AtomToggle
                                            :allow-null="true"
                                            :model-value="getFieldValueOfId(field)"
                                            :error-message="getErrorMessage(field)"
                                            @update:model-value="updateField(field, $event)"
                                        />
                                    </div>
                                    <div v-else-if="field.type === 'string' || field.type === 'text'">
                                        <p :class="[{'required-label': field.required}, 'order-field-name']">{{ field.name }}</p>
                                        <AtomTextInput
                                            :model-value="getFieldValueOfId(field)"
                                            :max-length="field.size"
                                            :error-message="getErrorMessage(field)"
                                            @update:model-value="updateField(field, $event)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="flex-1 notes">
                        <h3 class="spacing-bottom--l">{{ $t('General.notes') }}</h3>
                        <AtomProductNoteForm
                            :product-abstract-id="productAbstractId ?? ''"
                            :auto-save="true"
                            :is-cart-service="isCartService"
                        />
                    </div>
                </div>
            </template>
        </AtomAccordion>
    </div>
</template>
<script lang="ts" setup>
import type { AdditionalOrderField, AdditionalOrderFieldGroup } from '~/composables/types/api/searchDiscover';

interface Select {
    name: string,
    value: string
}

const props = defineProps<{
    groups: AdditionalOrderFieldGroup[] | undefined,
    brandCode: string,
    modelCode: string,
    productAbstractId: string | number | null,
    nosTabSelected: boolean,
    isCartService?: boolean,
}>();

const {
    loadAdditionalOrderFields,
    loadFieldValues,
    getFieldValues,
    updateAdditionalOrderFieldValues,
    getFieldValueOfId,
} = useAdditionalOrderFields();

const emit = defineEmits<{ (e: 'fieldsUpdated', valid: boolean): void }>();

const { $t } = useNuxtApp();
const toasts = useToasts();
const accordionOpen: Ref<boolean> = ref(true);
const fieldValues: Ref<Record<string, string | number | boolean | null>> = ref({});
const validForm = ref(true);
let timeoutId: ReturnType<typeof setTimeout> | null;
const UPDATE_TIMEOUT = 500;

const fieldIsValid = (fieldId: string) : boolean => {
    if (!props.groups) {
        return false;
    }

    for (const group of props.groups) {
        for (const field of group.fields) {
            if (`${field.fieldId}_${field.fieldsetId}` === fieldId) {
                if (!field.required) {
                    return true;
                }

                return fieldValues.value[`${field.fieldId}_${field.fieldsetId}`] !== undefined &&
                    fieldValues.value[`${field.fieldId}_${field.fieldsetId}`] !== '';
            }
        }
    }

    return false;
};

const validateForm = () => Object.keys(fieldValues.value).every((x) => fieldIsValid(x));

const getErrorMessage = (field: AdditionalOrderField) => {
    if (props.nosTabSelected) {
        return '';
    }

    return !fieldIsValid(`${field.fieldId}_${field.fieldsetId}`) ?
        $t('Matrix.matrix_order_fields_required_message') :
        '';
};

const updateData = async() => {
    const success = await updateAdditionalOrderFieldValues(fieldValues.value);
    if (!success) {
        toasts.add({
            type: 'error',
            headline: $t('Matrix.header_field_values_update_error_header'),
            text: $t('Matrix.header_field_values_update_error'),
        });
    }
};

const updateField = (field: any, value: string | number | boolean | null | undefined) => {
    if (fieldValues.value[`${field.fieldId}_${field.fieldsetId}`] === value) {
        return;
    }
    fieldValues.value[`${field.fieldId}_${field.fieldsetId}`] = value ?? '';
    validForm.value = validateForm();

    if (timeoutId) {
        clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
        updateData();
    }, UPDATE_TIMEOUT);

    emit('fieldsUpdated', validForm.value);
};

const getExactness = (number: number) => {
    const pow = 10;
    if (number <= 0) {
        return 0;
    }

    return 1 / Math.pow(pow, number);
};

const loadData = async() => {
    await loadAdditionalOrderFields();
    const headerValues = await loadFieldValues(props.brandCode, props.modelCode);
    if (!headerValues) {
        toasts.add({
            type: 'error',
            headline: $t('Matrix.header_field_values_load_error_header'),
            text: $t('Matrix.header_field_values_load_error'),
        });

        return;
    }

    fieldValues.value = { ...getFieldValues() };
};

const adjustSelect = (select: Select[]) => select.map(elem => ({
    name: elem.name,
    value: elem.value.toString()
}));

const getMaxValue = (length: number, precision: number) => {
    let number = '';

    for (let i = 0; i < length - precision; i++) {
        number += '9';
    }

    if (precision > 0) {
        number += '.';

        for (let i = 0; i < precision; i++) {
            number += '9';
        }
    }

    return parseFloat(number);
};

watch(accordionOpen, async(new_value) => {
    if (!props.groups) {
        return;
    }

    if (new_value && Object.keys(props.groups).length) {
        await loadData();
    }
});

onMounted(async() => {
    if (!props.groups) {
        return;
    }

    if (Object.keys(props.groups).length) {
        await loadData();
    }

    validForm.value = validateForm();
    emit('fieldsUpdated', validForm.value);
    accordionOpen.value = true;
});
</script>

<style lang="scss" scoped>
@mixin bestit-vsf-additionalFields($container: '.field-container', $numberInput: '.number-input', $accordion: '.sf-accordion',
$select: '.select-input') {
    :deep(.number-input__input-container) {
        width: 100%;
    }

    :deep(#{$accordion}) {
        padding: rem(15);
    }

    :deep(.sf-accordion-item__header) {
        font-size: fs(medium);
    }
}

@include bestit-vsf-additionalFields();

.required-label {
    @include helper-color(alert-success);
}

.required-label::after {
    content: '*';
}

.order-field-name {
    font-size: fs(small);
    text-align: left;
    margin-top: rem(10);
    margin-bottom: sp(xxs);

    @include helper-color(text-secondary);
}

.toggle {
    margin-top: sp(xs2);
}

h3 {
    font-size: fs(6);

    @include helper-color(text-title);
    @include helper-font-weight(medium);
}

.fields {
    margin-right: sp(m);
}
</style>
