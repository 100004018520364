<template>
    <div
        v-if='!!abstractProductIdOrSku'
        ref="interactiveZone"
        :class="{'content': true, 'cluster-loading': getClusterLoadingState()}"
    >
        <template v-if='loaded || backgroundReloading || matrixResponseData?.matrixError'>
            <AtomNotification
                v-if="loaded && !matrixResponseData?.matrixError && !matrixResponseData?.items?.loaded"
                v-role:not="RoleTypes.SUPPLIER"
                type="warning"
                :text="$t('Matrix.cart_items_missing')"
            />

            <template v-if="loaded && !matrixResponseData?.matrixError">
                <div :class="{'matrix-header': true, 'matrix-disabled': matrixDisabled, 'container': pdp }">
                    <MoleculeMatrixHeader
                        :is-matrix-modal="isMatrixModal"
                        :model-name="matrixResponseData?.name ?? ''"
                        :model-code="matrixResponseData?.modelNumberFrontend ?? ''"
                        :brand-id="matrixResponseData?.brandCode ?? ''"
                        :catalog-id="matrixResponseData?.catalogId ?? ''"
                        :brand-name="matrixResponseData?.brandName ?? ''"
                        :labels="matrixResponseData?.labels ?? []"
                        :image="matrixResponseData?.colors[0].imageUrl ?? ''"
                        :sku="abstractSku ?? ''"
                        :supplier-is-intersport="matrixResponseData?.intersportGLN ?? false"
                        :supplier-index='supplierIndex ?? 0'
                        :suppliers='matrixResponseData?.suppliers || []'
                        :concretes="matrixResponseData?.concreteProductsBySizeAndColor ?? {}"
                        :sections="matrixData?.branchSections ?? null"
                        :product-abstract-relations="matrixResponseData?.productAbstractRelations ?? null"
                        :pg-fedas4-name="matrixResponseData?.pgFedas4Name ?? null"
                        :pg-fedas4="matrixResponseData?.pgFedas4 ?? ''"
                        :hgg="matrixResponseData?.hgg ?? ''"
                        :pg-fedas-name="matrixResponseData?.pgFedasName ?? ''"
                        :product-abstract-id="matrixResponseData?.idProductAbstract.toString() ?? ''"
                        :customer-group-access="product?.customerGroupAccess ?? false"
                        :product-type="productType ?? ''"
                        @update:supplier-index="supplierChanged"
                        @close:close-matrix="emit('close:closeMatrix')"
                        @update:net-prices-changed="updateNetPricesChanged"
                    />
                </div>
                <div :class="{'tabs-wrapper': !isMatrixModal}">
                    <AtomTabs
                        v-if="!isMatrixModal"
                        v-model="tabIndex"
                        class="container detail-tabs"
                        :items="tabItems"
                        :hide-index="[
                        isDefaultProduct ? 1 : -1,
                        isVolumeDiscounted ? -1 : 3,
                        isRoleSupplier ? 0 : -1,
                        !$can(PermissionTypes.ORDER) && !$hasRole(RoleTypes.MEMBER) ? 0 : -1
                        ]"
                        :line-style="true"
                    />
                    <template v-if="isMatrixModal || (!isMatrixModal && tabIndex === 0)">
                        <MoleculeMatrixOptions
                            :class="{ 'container': pdp }"
                            :group-by-index="groupByIndex"
                            :quick-selection="quickSelection"
                            :quick-selection-count="quickSelectionCount"
                            :min-packaging-unit="matrixResponseData?.minPackagingUnit ?? 1"
                            :cart-id="props.cartId || activeCart?.id"
                            :is-cart-service="isCartService"
                            :user-flow-state="matrixResponseData?.items.userFlowState ?? UserflowTypes.EDITING"
                            :brand-code="matrixResponseData?.brandCode ?? ''"
                            :model-code="matrixResponseData?.modelNumberFrontend ?? ''"
                            :supplier-id="matrixResponseData?.supplierNumber ?? ''"
                            :cart-closed="matrixResponseData?.items.cartClosed ?? true"
                            :module-items="matrixResponseData?.items.moduleItems ?? []"
                            :order-items="matrixResponseData?.items.orderItems ?? []"
                            :product-abstract-id="currentProductIdOrSku?.toString() ?? ''"
                            :sum-data="sumData"
                            :branch-clusters-available="matrixResponseData?.branchClustersAvailable ?? false"
                            :branch-cluster-selected="matrixResponseData?.items.branchCluster ?? null"
                            :nos-tab-selected="nosTabSelected"
                            :min-stock-selected="!showOrderTarget"
                            @update:group-by-index="groupByIndexChanged"
                            @update:quick-selection="quickSelectionChanged"
                            @update:quick-selection-count="quickSelectionCountChanged"
                            @update:toggle-state-changed="updateToggleState"
                            @reset-clicked="resetClicked"
                        />
                        <MoleculeMatrixTabs
                            :class="{'container': pdp}"
                            :delivery-dates='deliveryDates'
                            :nos-delivery-dates='nosDeliveryDates'
                            :delivery-date-index='deliveryDateIndex'
                            :delivery-date-nos-index='deliveryDateNosIndex'
                            :nos-tab-visible="Boolean(matrixResponseData?.nosTabVisible)"
                            :nos-tab-disabled="Boolean(!matrixResponseData?.items.nosLoaded)"
                            :nos-tab-selected="nosTabSelected"
                            :cart-closed="matrixResponseData?.items.cartClosed ?? true"
                            :matrix-disabled="matrixDisabled"
                            :allow-create-delivery-date="!matrixResponseData?.items?.moduleItems ||
                                matrixResponseData.items.moduleItems.length === 0 ||
                                matrixResponseData.items.moduleItems.some((item: MatrixModuleItem) => item.qtySplitOverDeliveryDates)"
                            @update:delivery-date-index='deliveryDateChanged'
                            @update:supplier-index='supplierChanged'
                            @update:nos-tab-selected='nosTabUpdated'
                            @create-new-delivery-date='createNewDeliveryDate'
                            @edit-delivery-date='editDeliveryDate'
                            @edit-nos-delivery-date='editNosDeliveryDate'
                            @create-new-nos-date='createNewDeliveryDate'
                        />
                        <MoleculeMatrixFeedback
                            v-if="nosTabSelected"
                            :class="{ 'container': Boolean(pdp) }"
                            :nos-tab-selected="nosTabSelected"
                            :show-order-target="showOrderTarget"
                            :is-matrix-table="true"
                            @update:show-order-target="updateOrderTarget"
                        />
                        <MoleculeMatrixContent
                            v-if="matrixData"
                            ref="matrixContent"
                            :class="{'matrix-disabled-columns': matrixDisabled, 'matrix-submitting-data': !quantitiesSubmitted }"
                            :enabled="!matrixDisabled && quantitiesSubmitted"
                            :data="matrixData"
                            :get-cart-quantity="nosTabSelected ? getNosQuantityContent : getCartQuantityContent"
                            :get-nos-standard-stock="nosTabSelected ? getNosStandardStockContent : getCartQuantityContent"
                            :get-cart-module-quantity="getCartModuleQuantityContent"
                            :min-quantity="matrixResponseData?.minQuantityConcrete ?? 1"
                            :quick-selection="quickSelection"
                            :quick-selection-count="quickSelectionCount"
                            :group-by-index="groupByIndex"
                            :sum="sumDataCurrent"
                            :delivery-dates="deliveryDates?.[deliveryDateIndex]?.text ?? ''"
                            :active-tab-deadline-date="getDeadlineDateForActiveTab"
                            :nos-tab-selected="nosTabSelected"
                            :show-order-target="showOrderTarget"
                            :scrollable-zone="updateScrollContainer()"
                            :pdp="pdp"
                            :matrix-cart-items-needs-reload="cartItemsNeedsToReload"
                            @quantity-updated="updateQuantities"
                        />
                        <div ref="additionalOrderFieldsEl">
                            <OrganismAdditionalOrderFields
                                v-role:not="RoleTypes.SUPPLIER"
                                :class="{'container': pdp}"
                                :groups='additionalOrderFieldGroups'
                                :fields-valid="additionalFieldsValid"
                                :brand-code="matrixResponseData?.brandCode ?? ''"
                                :model-code="matrixResponseData?.modelNumberFrontend ?? ''"
                                :product-abstract-id="currentProductIdOrSku"
                                :nos-tab-selected="nosTabSelected"
                                :is-cart-service="isCartService"
                                @valid-changed='additionalFieldsValid = $event'
                                @fields-updated='additionalFieldsValid = $event'
                            />
                        </div>
                        <MoleculeMatrixSum
                            :class="{'container': pdp}"
                            :data="sumData"
                            :nos-tab-selected="nosTabSelected"
                        />
                        <MoleculeMatrixDates
                            v-if="dateCreation !== 0"
                            :date-type="dateCreation"
                            :date-ranges="dateCreationRanges"
                            :reserved-dates="dateCreationReservedDates"
                            :nos-tab-selected="nosTabSelected"
                            :nos-valid-to-date="nosValidToDate"
                            :is-edit="isDateEdit"
                            :initial-date="editDate"
                            :min-deadline-date="getMinDeadlineForDeliveryDate"
                            :available-colors="getAvailableColorsForDeliveryDate"
                            :products-not-available-at-date="productsNotAvailableAtDate"
                            :catalog-id="matrixResponseData?.catalogId ?? ''"
                            :supplier="matrixResponseData?.supplierName ?? ''"
                            :delivery-dates="deliveryDates"
                            :supplier-is-intersport="matrixResponseData?.intersportGLN ?? false"
                            @date-selection-saved="dateSelectionSaved"
                            @date-selection-closed="dateSelectionClosed"
                            @date-selection-delete="dateSelectionDeleted"
                        />
                    </template>
                </div>
            </template>

            <template v-else-if="matrixResponseData?.matrixError">
                <MoleculeErrorPageContent :status-code="404" :show-buttons="false" />

                <AtomModal
                    v-model:open="orderRedirectModalOpen"
                    class="matrix-redirect-to-orders"
                    :title="$t('Matrix.order_redirect_title')"
                    :text="$t('Matrix.order_redirect_text')"
                    :buttons="orderRedirectModalButtons"
                    :show-x-button="false"
                    @button-clicked="orderRedirectClicked"
                />
            </template>

            <AtomModal
                v-model:open="resetModalOpen"
                class="matrix-reset-modal"
                :title="$t('Matrix.reset_title')"
                :text="$t('Matrix.reset_text')"
                :buttons="resetModalButtons"
                :show-x-button="false"
                @button-clicked="resetModalClicked"
            />
        </template>

        <AtomSpinner
            v-if="(!loaded && !matrixResponseData?.matrixError) || getClusterLoadingState() === true"
            class="matrix-spinner"
            :response="matrixResponseData"
        >
            <template v-if="getClusterLoadingState() === true" #spinnerContext>
                <span>{{ $t('Matrix.cluster_loading') }}</span>
            </template>
        </AtomSpinner>
    </div>
</template>

<script lang="ts" setup>
import useMatrixDateHelper from '~/composables/matrix/useMatrixDateHelper';
import { useI18n } from 'vue-i18n';
import useMatrixDataLoader from '~/composables/service/matrix/useMatrixDataLoader';
import type {
    MatrixContentData
} from '~/composables/types/matrix/useMatrixContentDataTypes';
import useMatrixContentData from '~/composables/matrix/useMatrixContentData';
import type {
    MatrixModuleItem,
    MatrixOrderItem,
    NosQuantityUpdate
} from '~/composables/types/api/cartConditions/matrix';
import useMatrixWarnings from '~/composables/matrix/useMatrixWarnings';
import type { AdditionalOrderFieldGroup } from '~/composables/types/api/searchDiscover';
import type { ModalButton, TabItem } from '~/composables/types/ui';
import useMatrixNosItems from '~/composables/service/matrix/useMatrixNosItems';
import useMatrixOrderItems, {
    type MatrixModuleItemValidation,
    type MatrixStoredOrderItem
} from '~/composables/service/matrix/useMatrixOrderItems';
import {
    type BranchClusterState,
    useBranchClusterStore
} from '~/composables/stores/useBranchClusterStore';
import { DISPLAYS, LOTS } from '~/composables/utils/applicationConstants';
import type { AbstractProductsResult } from '~/composables/types/api/searchDiscover/product';
import { ProductTypes } from '~/composables/types/productTypes';
import { useMatrixPricesStore } from '~/composables/stores/useMatrixPricesStore';
import { RoleTypes } from '~/composables/types/roleTypes';
import { PermissionTypes } from '~/composables/types/permissionTypes';
import { UserflowTypes } from '~/composables/types/userflowTypes';
import type {
    EnrichedMatrixDataSet,
    MatrixAvailableDeliveryDate,
    MatrixSumProps,
    MatrixSupplier
} from '~/composables/types/api/searchDiscover/getMatrix';
import { StoreTypes } from '~/composables/types/storeTypes';

const props = withDefaults(defineProps<{
    abstractProductIdOrSku: string | null,
    abstractSku?: string,
    cartId?: string | null,
    pdp?: boolean,
    isCartService?: boolean,
    isMatrixModal?: boolean,
    product?: AbstractProductsResult,
    productType?: string,
}>(), {
    product: undefined,
    productType: undefined,
    abstractSku: '',
    cartId: null,
    pdp: false,
    isCartService: false,
    isMatrixModal: false,
});

const { $can, $emitter, $isStore, $hasRole, $t } = useNuxtApp();
const t = useI18n().t;
const toasts = useToasts();
const matrixPricesStore = useMatrixPricesStore();
const feedBackDelay = 500;

const groupByBranch = 'branch';
const groupByColor = 'color';
const groupByValues = [groupByBranch, groupByColor];
const interactiveZone = ref<HTMLElement | null>(null);

const {
    getCachedData: getGroupByKeyStorage,
    updateCachedData: updateGroupByKeyStorage,
} = useLocalStorageCache<string>(LocalStorageNameTypes.LAST_SELECTED_MATRIX_GROUP_BY);

const {
    getCachedData: getQuickSelectionStorage,
    updateCachedData: updateQuickSelectionStorage,
} = useLocalStorageCache<boolean>(LocalStorageNameTypes.LAST_SELECTED_QUICK_SELECTION);

const emit = defineEmits<{
    (e: 'update:loading', value: boolean): void,
    (e: 'close:closeMatrix'): void,
    (e: 'updateTabIndex', tabIndex: number): void,
}>();

const {
    update: updateBranchClusterTemplate,
    get: branchClusterTemplate,
    getClusterLoadingState,
    setClusterLoadingState,
    getClusterCount,
    getClusterState,
} = useBranchClusterStore();
const cartsStore = useCartsStore();
const {
    activeCart,
} = storeToRefs(cartsStore);
const loaded = ref(false);
// if true, instead of showing spinner, matrix is disabled while loading new content
const backgroundReloading = ref(false);
const putTimerUp = ref(false);
const matrixData = shallowRef<MatrixContentData | null>(null);
const additionalOrderFieldGroups = shallowRef<AdditionalOrderFieldGroup[]>();
const sumData = ref() as Ref<MatrixSumProps[]>;
const sumDataCurrent = ref() as Ref<MatrixSumProps>;
const dateCreation = ref(0);
const dateCreationRanges = ref<MatrixAvailableDeliveryDate[]>([]);
const dateCreationReservedDates = ref<string[]>([]);
const isDateEdit = ref(false);
const editDate = ref('');
const additionalOrderFieldsEl = ref();
const additionalFieldsValid = ref(true);
const groupBy = ref<string>(groupByBranch);
const supplierIndex = ref(0);
const groupByIndex = ref<number>(groupByValues.indexOf(groupBy.value));
const quickSelection = ref(true);
const quickSelectionCount = ref(1);
const resetModalOpen = ref(false);
const nosTabSelected: Ref<boolean> = ref(false);
const showOrderTarget: Ref<boolean> = ref(false);
const itemInCart: Ref<boolean> = ref(false);
const matrixResponseData = shallowRef<EnrichedMatrixDataSet | null>(null);
const orderRedirectModalOpen = ref(false);
let editDateIndex = -1;
let currentProductIdOrSku = props.abstractProductIdOrSku;
const cartItemsNeedsToReload = ref(false);

const updateScrollContainer = () => interactiveZone.value?.parentElement || null;

const matrixDisabled = computed(
    () => !loaded.value || !matrixResponseData.value?.items?.loaded || !$can(PermissionTypes.ORDER) || $hasRole(RoleTypes.SUPPLIER)
);

const showToast = (
    type: 'success' | 'warning' | 'error' | 'info',
    headlineKey: string,
    textKey: string,
    interpolations: string[] = []
) => {
    toasts.add({
        type,
        headline: t(headlineKey),
        text: t(textKey, interpolations),
    });
};

const cartUuid = computed(() => props.cartId ?? null);

const {
    loadMatrixData,
    loadMatrixCartItems,
    getResponseData,
    linkToOrder,
} = useMatrixDataLoader(cartUuid);

const onUpdateError = (error: any) => {
    const config = useRuntimeConfig();

    if (!(error instanceof Error)) {
        error = typeof error === 'string' ? new Error(error) : new Error(JSON.stringify(error));
    }

    if (config.bugsnagIsActive) {
        // @ts-ignore
        useBugsnag().notify(error);
    } else {
        console.error(error);
    }

    toasts.clear('success');

    if (!linkToOrder) {
        showToast('error', 'Matrix.post_error', 'Matrix.post_error_text');
    }
};

const {
    updateDateData,
    deleteDeliveryDate,
    createDeliveryDate,
    updateDeliveryDate,
    gtinsExpiredForNewDate,
    updateNosDeliveryDate,
    getAllAvailableDeliveryDates,
    getAllAvailableDeliveryDatesByGtin,
    getMinDeadlineForDeliveryDate,
    createDateString,
    filterAvailableDeliveryDates,
    deliveryDates,
    nosDeliveryDates,
    nosValidToDate,
    deliveryDateType,
    deliveryDateIndex,
    deliveryDateNosIndex,
    currentDeliveryDate,
    currentNosDeliveryDate,
    stringToDate
} = useMatrixDateHelper();

const {
    updateCartData,
    matrixClosed,
    getCartQuantity,
    validateModuleItemQuantity,
    calculateAdjustedQuantity,
    getCartQuantitiesForDate,
    getCartModuleQuantity,
    getCartQuantitiesByBranches,
    updateMatrixOrderItemQuantity,
    resetMatrixOrderItemQuantities,
    updateUserflowToggleState,
    updateNetPricesChangedState,
    updateMatrixOrderItemsBranchCluster,
    quantitiesSubmitted
} = useMatrixOrderItems(onUpdateError, cartUuid);

const {
    updateNosData,
    getNosQuantity,
    getNosStandardStock,
    getNosQuantitiesForDate,
    updateMatrixNosQuantity,
    resetMatrixNosQuantity,
} = useMatrixNosItems(onUpdateError, props.cartId ?? null);

const {
    checkMinQuantities,
} = useMatrixWarnings();

const {
    loadAdditionalOrderFields,
    getAdditionalOrderFieldGroups,
} = useAdditionalOrderFields();

const tabIndex = ref(0);
const tabItems: Ref<TabItem[]> = ref([
    {
        text: $t('Product.matrix'),
        icon: 'matrix',
    },
    {
        text: $t('Product.item_list'),
        icon: 'list',
    },
    {
        text: $t('Product.product_info'),
        icon: 'info',
    },
    {
        text: $t('Product.volume_discount'),
        icon: 'trello',
    }
]);

const isVolumeDiscounted = computed(() => props.product?.labels?.includes('MS') === true);
const isDefaultProduct = computed(() => props.product?.type && !LOTS.includes(props.product?.type) && !DISPLAYS.includes(props.product?.type));
const isRoleSupplier = computed(() => $hasRole(RoleTypes.SUPPLIER));
watch(() => tabIndex.value, () => {
    emit('updateTabIndex', tabIndex.value);
});

const selectedDeliveryDate = nosTabSelected.value ? currentNosDeliveryDate : currentDeliveryDate;

const getCartQuantityContent = (branchId: string, gtin: string) => getCartQuantity(currentDeliveryDate.value?.value, branchId, gtin);
const getCartModuleQuantityContent = (branchId: string, gtin: string) => getCartModuleQuantity(deliveryDateIndex.value, branchId, gtin);
const getNosQuantityContent = (branchId: string, gtin: string) => getNosQuantity(currentNosDeliveryDate.value?.value, branchId, gtin);
const getNosStandardStockContent = (branchId: string, gtin: string) => getNosStandardStock(currentNosDeliveryDate.value?.value, branchId, gtin);

const updateMatrixTabQuantities = () => {
    deliveryDates.value.forEach((deliveryDate, index) => {
        deliveryDates.value[index].totalQuantity = getCartQuantitiesForDate(deliveryDate.value)
            .map(currentDate => {
                const clusterCount = getClusterCount(currentDate.branchId);

                return currentDate.quantity ? currentDate.quantity * clusterCount : 0;
            }).reduce((acc, currentQuantity) => acc + currentQuantity, 0);
    });
};

const updateNosTabQuantities = () => {
    nosDeliveryDates.value.forEach((deliveryDate, index) => {
        nosDeliveryDates.value[index].totalQuantity = getNosQuantitiesForDate(deliveryDate.value)
            .map(currentDate => (currentDate.minStock && currentDate.minStock > 0 ? currentDate.minStock : 1))
            .reduce((acc, currentQuantity) => acc + currentQuantity, 0);
    });
};

const updateSums = () => {
    const sum: MatrixSumProps[] = [];
    updateMatrixTabQuantities();
    if (matrixResponseData.value?.items.nosItems?.length) {
        updateNosTabQuantities();
    }

    const deliveryDatesToUse = nosTabSelected.value ? nosDeliveryDates : deliveryDates;

    deliveryDatesToUse.value.forEach((deliveryDate) => {
        const quantities = nosTabSelected.value ?
            getNosQuantitiesForDate(deliveryDate.value) :
            getCartQuantitiesForDate(deliveryDate.value);

        let totalCount = 0;
        let standardStockCount = 0;
        let ekTotal = 0;
        let vkTotal = 0;

        quantities.forEach((quantity) => {
            const clusterCount = getClusterCount(quantity.branchId);
            const standardStock = 'standardStock' in quantity ? quantity.standardStock || 0 : 0;
            const minStock = 'minStock' in quantity ? quantity.minStock : 0;
            let selectedQuantities = 0;

            if (nosTabSelected.value) {
                selectedQuantities = minStock || 0;
            } else if ('quantity' in quantity) {
                selectedQuantities = ((quantity.quantity ?? 1) * clusterCount) || 0;
            }

            totalCount += selectedQuantities;
            standardStockCount += standardStock;

            const storeEkPrice = matrixPricesStore.getEkSinglePriceForGtin(quantity.gtin);
            const product = matrixResponseData.value?.concreteProductsByGtin[quantity.gtin];
            if (storeEkPrice) {
                ekTotal += storeEkPrice * selectedQuantities;
            } else {
                if (product?.purchasePrice) {
                    ekTotal += product.purchasePrice * selectedQuantities;
                }
            }
            if (product?.sellPrice && !nosTabSelected.value) {
                const quantityDeliveryDate = !nosTabSelected.value ? (quantity as MatrixStoredOrderItem).deliveryDate : '';
                const upePrice = matrixPricesStore.getUPEPriceForCell(
                    product?.gtin ?? '',
                    product.sellPrice,
                    quantityDeliveryDate
                );
                vkTotal += upePrice * selectedQuantities;
            }
        });

        if (selectedDeliveryDate?.value?.value === deliveryDate.value || nosTabSelected.value) {
            sumDataCurrent.value = {
                sumPurchasePrice: ekTotal,
                sumSellPrice: vkTotal,
                productsCount: totalCount,
                productsStandardStockCount: standardStockCount,
                supplierName: matrixResponseData.value?.suppliers?.[supplierIndex.value]?.text ?? '',
                deadline: stringToDate(getMinDeadlineForDeliveryDate(selectedDeliveryDate?.value?.value || '')),
                deliveryDate: stringToDate(deliveryDate.value),
            };
        }

        sum.push({
            sumPurchasePrice: ekTotal,
            sumSellPrice: vkTotal,
            productsCount: totalCount,
            productsStandardStockCount: standardStockCount,
            supplierName: matrixResponseData.value?.suppliers?.[supplierIndex.value]?.text ?? '',
            deadline: stringToDate(getMinDeadlineForDeliveryDate(selectedDeliveryDate?.value?.value || '')),
            deliveryDate: stringToDate(deliveryDate.value),
        });
    });

    sumData.value = sum;
};

const updateOrderTarget = (orderTarget: boolean) => {
    updateSums();
    showOrderTarget.value = orderTarget;
};

const updateMatrixContentData = (reset?: boolean) => {
    if (!matrixResponseData?.value) {
        return;
    }

    const shouldReset = reset || false;

    const resetDateValues = shouldReset ?
        matrixResponseData.value.items.orderItems.filter(
            (orderItem: MatrixOrderItem) => orderItem.deliveryDate !== selectedDeliveryDate.value?.value
        ) :
        [];

    matrixResponseData.value.items.orderItems = shouldReset ? resetDateValues : matrixResponseData.value.items.orderItems;

    matrixData.value = useMatrixContentData(
        matrixResponseData.value,
        getAllAvailableDeliveryDatesByGtin
    ).getMatrixContentData(selectedDeliveryDate.value?.value, groupBy.value, nosTabSelected.value);

    if (!nosTabSelected.value) {
        checkMinQuantities(matrixResponseData.value, getCartQuantitiesByBranches(), groupBy.value === groupByBranch);
    }
};

const updateItemInCarts = () => {
    itemInCart.value = false;
};

const reloadData = async(loadDifferentSupplier = false, nosTabFocus = false) => {
    if (!currentProductIdOrSku) {
        return;
    }

    backgroundReloading.value = loadDifferentSupplier;
    loaded.value = false;
    matrixResponseData.value = null;
    updateBranchClusterTemplate(null);
    setClusterLoadingState(null);

    try {
        const [matrixDataSuccess, additionalFieldsSuccess] = await Promise.all([
            loadMatrixData(currentProductIdOrSku),
            loadAdditionalOrderFields()
        ]);

        orderRedirectModalOpen.value = typeof linkToOrder.value !== 'undefined';

        if (!(matrixDataSuccess && additionalFieldsSuccess)) {
            matrixResponseData.value = getResponseData();
            if (matrixResponseData.value.matrixError?.status !== ResponseStatusTypes.NOT_FOUND) {
                toasts.add('LOAD_ERROR');
            }
            backgroundReloading.value = false;
            loaded.value = true;

            return;
        }

        matrixResponseData.value = getResponseData();

        nosTabSelected.value = matrixResponseData.value.nosTabVisible && nosTabFocus;
        const previousSuppliers = [...(matrixResponseData.value.suppliers || [])];
        additionalOrderFieldGroups.value = getAdditionalOrderFieldGroups();

        if (loadDifferentSupplier && previousSuppliers.length > 0) {
            matrixResponseData.value.suppliers = previousSuppliers;
        }

        supplierIndex.value = matrixResponseData.value.suppliers?.findIndex(
            (supplier: MatrixSupplier) => supplier.gln === matrixResponseData.value!.supplierGln &&
            (supplier.abstractProductId.toString() === currentProductIdOrSku?.toString() ||
                supplier.abstractSku.toString() === currentProductIdOrSku?.toString())
        );

        updateDateData(matrixResponseData.value, 0);
        updateCartData(matrixResponseData.value);

        $emitter.$on('updateOrderItemInCarts', updateItemInCarts);

        if (matrixResponseData.value.nosTabVisible && matrixResponseData.value.items.nosLoaded) {
            updateNosData(matrixResponseData.value);
        }

        updateMatrixContentData();
        updateSums();
        quickSelectionCount.value = matrixResponseData.value?.maxPackagingUnit ?? 1;

        loaded.value = true;

        nextTick(() => {
            $emitter.$emit('onMatrixQuantitiesUpdated', matrixResponseData.value?.items.orderItems.reduce(
                (sum, { quantity = 0 }) => sum + quantity, 0
            ));
        });
    } catch (error) {
        onUpdateError(error);
    }
};

const resetModalButtons = ref([
    {
        text: t('General.cancel'),
        type: 'secondary',
    },
    {
        text: t('Matrix.reset'),
    },
]) as Ref<ModalButton[]>;

const orderRedirectModalButtons = ref([
    {
        text: t('General.cancel'),
        type: 'secondary',
    },
    {
        text: t('Matrix.redirect'),
    },
]) as Ref<ModalButton[]>;

const updateBranchCluster = (template: BranchClusterState['branchClusterTemplate']) => {
    if (template !== null && template.resetData) {
        resetMatrixOrderItemQuantities();
        updateSums();
        updateMatrixContentData(true);
        template.resetData = false;
    }

    if (!template && matrixResponseData.value) {
        matrixResponseData.value.branches = matrixResponseData.value.defaultBranches;
        updateCartData(matrixResponseData.value);

        if (getClusterState()) {
            updateSums();
            updateMatrixOrderItemsBranchCluster(null);
            updateMatrixContentData();
            showToast('success', 'Matrix.branch_cluster_update_title', 'Matrix.branch_cluster_update_text_disabled');
        }

        return;
    }

    if (template) {
        const selectedCluster = branchClusterTemplate()?.clusters.flatMap(cluster => cluster.name);
        if (matrixResponseData.value && selectedCluster?.length) {
            matrixResponseData.value.branches = selectedCluster;
            updateCartData(matrixResponseData.value);
            updateSums();

            if (getClusterState() || template.resetData) {
                updateMatrixOrderItemsBranchCluster(template);
                updateMatrixContentData();

                if (!template?.initialClusterSetup) {
                    showToast('success', 'Matrix.branch_cluster_update_title', 'Matrix.branch_cluster_update_text_changed');
                }
            }
        }
    }
};

const quickSelectionCountChanged = (value: number) => {
    quickSelectionCount.value = value;
};

watch(() => currentDeliveryDate.value, () => {
    matrixPricesStore.setActiveMatrixDate(currentDeliveryDate.value.value);
});

watch(() => props.abstractProductIdOrSku, () => {
    currentProductIdOrSku = props.abstractProductIdOrSku;
    reloadData();
});

watch(() => [showOrderTarget.value, nosTabSelected.value], () => {
    if (nosTabSelected.value) {
        quickSelectionCount.value = showOrderTarget.value ?
            matrixResponseData.value?.maxPackagingUnit ?? 1 :
            1;
    } else {
        quickSelectionCount.value = matrixResponseData.value?.maxPackagingUnit ?? 1;
    }
});

watch(branchClusterTemplate, () => {
    if (nosTabSelected.value) {
        return;
    }

    const template = branchClusterTemplate();

    updateBranchCluster(template);
});

watch([loaded, backgroundReloading], () => {
    emit('update:loading', !loaded.value || backgroundReloading.value);
});

const updateQuantitiesResponse = async(success: boolean) => {
    quantitiesSubmitted.value = true;

    if (!putTimerUp.value) {
        await new Promise(resolve => setTimeout(resolve, feedBackDelay));
    }

    if (!success) {
        showToast('error', 'Matrix.post_error', 'Matrix.post_error_text');

        return;
    }

    $emitter.$emit(
        'onMatrixQuantitiesUpdated', matrixResponseData.value?.items.orderItems.reduce((sum: number, { quantity = 0 }) => sum + quantity, 0)
    );
};

const startUpdateQuantities = async() => {
    putTimerUp.value = false;
    await new Promise(resolve => setTimeout(resolve, feedBackDelay));
    putTimerUp.value = true;
};

const groupByIndexChanged = (index: number) => {
    groupByIndex.value = index;
    groupBy.value = groupByValues[index];
    updateGroupByKeyStorage(groupBy.value);
    updateMatrixContentData();
};

const deliveryDateChanged = (index: number) => {
    deliveryDateIndex.value = index;

    if (!matrixResponseData.value) {
        return;
    }
    updateSums();
    updateMatrixContentData();
};

const nosTabUpdated = (active: boolean, index: number) => {
    nosTabSelected.value = active;

    if (active) {
        deliveryDateIndex.value = -1;
    }

    if (!matrixResponseData.value) {
        return;
    }

    if (active) {
        matrixResponseData.value.branches = matrixResponseData.value?.defaultBranches;
        updateBranchClusterTemplate(null);
    }

    updateDateData(matrixResponseData.value, index, false);
    updateCartData(matrixResponseData.value);
    updateSums();
    updateMatrixContentData();
};

const getAvailableColorsForDeliveryDate = (targetDate: string): { label: string }[] => {
    const now = createDateString();

    return Object.entries(matrixResponseData.value!.colorAvailabilities)
        .filter(([, availabilities]) => availabilities.some(
            availability => targetDate >= availability.fromDate && targetDate <= availability.toDate && availability.deadlineDate >= now
        )).map(
            ([colorKey]) => {
                const currentColor = matrixResponseData.value!.colors.find(color => color.key === colorKey);

                return currentColor ? { label: currentColor.label } : null;
            }
        ).filter(Boolean) as { label: string }[];
};

const productsNotAvailableAtDate = (targetDate: string, originalDate: string) => {
    const quantities = getCartQuantitiesForDate(originalDate);

    return quantities.some(quantity => quantity.quantity && quantity.quantity > 0 &&
        !matrixResponseData.value!.availableDeliveryDatesByGtin[quantity.gtin].some(
            x => targetDate >= x.fromDate && targetDate <= x.toDate
        ));
};

const setupDeliveryDateCreation = () => {
    dateCreationRanges.value = filterAvailableDeliveryDates(getAllAvailableDeliveryDates);
    dateCreationReservedDates.value = deliveryDates?.value?.map(deliveryDate => deliveryDate.value);
    dateCreation.value = deliveryDateType.value;
};

const createNewDeliveryDate = () => {
    editDate.value = '';
    isDateEdit.value = false;
    setupDeliveryDateCreation();
};

const editDeliveryDate = (index: number) => {
    editDateIndex = index;
    editDate.value = deliveryDates.value[index].value;
    isDateEdit.value = true;
    setupDeliveryDateCreation();
};

const editNosDeliveryDate = (index: number) => {
    editDateIndex = index;
    editDate.value = nosDeliveryDates.value[index].value;
    isDateEdit.value = true;
    setupDeliveryDateCreation();
};

const dateSelectionClosed = () => {
    dateCreation.value = 0;
    editDate.value = '';
    editDateIndex = -1;
};

const updateNosItems = (savedDate: string, validToDate?: string) => {
    matrixResponseData.value?.items.nosItems?.forEach(obj => {
        obj.validFrom = savedDate;
        obj.validTo = validToDate ?? '';
    });
};

const checkDateForItems = (savedDate: string): boolean => deliveryDates.value.some(
    (item) => item.value === savedDate && item.totalQuantity && item.totalQuantity > 0
);

const checkDateForNosItems = (savedDate: string): boolean => nosDeliveryDates.value.some(
    (item) => item.value === savedDate && item.totalQuantity && item.totalQuantity > 0
);

const dateSelectionSaved = (savedDate: string, validToDate?: string) => {
    if (editDateIndex === -1) {
        createDeliveryDate(savedDate, nosTabSelected.value);
        updateMatrixContentData();
        updateMatrixTabQuantities();
        showToast('success', 'Matrix.delivery_date_created', 'Matrix.delivery_date_created_text');
    } else if (editDate.value !== savedDate || nosValidToDate.value !== validToDate) {
        nosValidToDate.value = validToDate ?? '';
        if (!nosTabSelected.value) {
            updateDeliveryDate(savedDate, editDate.value, getCartQuantitiesForDate, updateMatrixOrderItemQuantity);
            updateMatrixTabQuantities();
        } else {
            updateNosItems(savedDate, validToDate);
            updateNosDeliveryDate(savedDate, editDate.value, getNosQuantitiesForDate, updateMatrixNosQuantity, nosValidToDate.value);
            updateNosTabQuantities();
        }

        if (gtinsExpiredForNewDate.value.length) {
            showToast(
                'warning',
                'Matrix.update_delivery_date_gtin_not_available_headline',
                'Matrix.update_delivery_date_gtin_not_available_text',
                [gtinsExpiredForNewDate.value.join(', ')]
            );

            return;
        }

        if (nosTabSelected.value ? checkDateForNosItems(savedDate) : checkDateForItems(savedDate)) {
            showToast('success', 'Matrix.delivery_date_updated', 'Matrix.delivery_date_updated_text');
        } else {
            showToast('warning', 'Matrix.delivery_date_not_updated', 'Matrix.delivery_date_not_updated_text');
        }
    }
    dateSelectionClosed();
    updateMatrixContentData();
    updateSums();
};

const validateOrderItems = (orderItemsToDelete?: MatrixModuleItemValidation): boolean => {
    const orderValidationData = {
        items: [],
        deliveryDate: selectedDeliveryDate.value?.value,
        increase: false
    };
    const moduleTypeArticle = matrixResponseData.value?.items.moduleItems.some(
        module => module.quantityValidationLevel === ProductTypes.ARTICLE
    ) || false;

    if (!validateModuleItemQuantity(orderItemsToDelete ?? orderValidationData, moduleTypeArticle)) {
        showToast(
            'warning',
            `Matrix.module_minQuantity${moduleTypeArticle ? '_article' : ''}_headline`,
            `Matrix.module_minQuantity${moduleTypeArticle ? '_article' : ''}_message`
        );

        return false;
    }

    return true;
};

const dateSelectionDeleted = () => {
    if (!nosTabSelected.value && !validateOrderItems()) {
        return;
    }
    deleteDeliveryDate(editDate.value, getCartQuantitiesForDate, updateMatrixOrderItemQuantity);
    updateMatrixContentData();

    toasts.clear('success');
    showToast('success', 'Matrix.delivery_date_deleted', 'Matrix.delivery_date_deleted_text');

    dateSelectionClosed();
};

const quickSelectionChanged = (value: boolean) => {
    quickSelection.value = value;
    updateQuickSelectionStorage(value);
};

const updateToggleState = (changed: boolean) => {
    if (changed) {
        updateUserflowToggleState(changed);
    }
};

const updateNetPricesChanged = (changed: boolean) => {
    if (changed) {
        updateNetPricesChangedState(changed);
        if (getCartQuantitiesForDate(currentDeliveryDate.value.value).length > 0) {
            cartItemsNeedsToReload.value = true;
        }
    }
};

const supplierChanged = (index: number) => {
    supplierIndex.value = index;
    currentProductIdOrSku = matrixResponseData.value!.suppliers[index].abstractProductId;
    reloadData(true);
};

const showAdditionalFieldsWarning = () => {
    toasts.toasts.length > 0 ? toasts.clear('warning') : {};
    toasts.add({
        type: 'warning',
        headline: t('Matrix.additional_fields_invalid_headline'),
        text: t('Matrix.additional_fields_invalid_messages'),
        buttons: [t('Matrix.fill_now')],
    }, {
        buttonClick: (index: number, close: () => void) => {
            additionalOrderFieldsEl.value.scrollIntoView({ behavior: 'smooth' });
            close();
        },
    });
};

const updateQuantities = (quantities: NosQuantityUpdate[], increase: boolean) => {
    if (!matrixResponseData.value || quantities.length === 0) {
        return;
    }

    if (!additionalFieldsValid.value && !nosTabSelected.value) {
        showAdditionalFieldsWarning();

        return;
    }

    const showRoundedUpAlert = false;
    startUpdateQuantities();

    const orderValidationData = {
        items: quantities,
        deliveryDate: currentDeliveryDate.value?.value,
        increase: increase
    };

    if (!nosTabSelected.value && !validateOrderItems(orderValidationData)) {
        return;
    }

    for (const quantity of quantities) {
        let adjustedQuantity: number | undefined = quantity.quantity;
        const nosStandardStockValue: number | undefined = calculateAdjustedQuantity(Number(quantity.standardStock), quantity.gtin);

        if (nosTabSelected.value) {
            if (showOrderTarget.value) {
                if (nosStandardStockValue === undefined) {
                    continue;
                }
            }
        } else {
            adjustedQuantity = calculateAdjustedQuantity(quantity.quantity, quantity.gtin);
        }

        if (adjustedQuantity === undefined) {
            continue;
        }

        if (nosTabSelected.value && 'standardStock' in quantity) {
            updateMatrixNosQuantity({
                gtin: quantity.gtin,
                branchId: quantity.branchId,
                validFrom: currentNosDeliveryDate.value?.value,
                validTo: nosValidToDate.value,
                minStock: adjustedQuantity > Number(nosStandardStockValue) ? nosStandardStockValue : adjustedQuantity,
                standardStock: nosStandardStockValue,
            });
        } else {
            updateMatrixOrderItemQuantity({
                gtin: quantity.gtin,
                branchId: quantity.branchId,
                deliveryDate: currentDeliveryDate.value?.value,
                quantity: adjustedQuantity,
                colorKey: matrixResponseData?.value?.concreteProductsByGtin?.[quantity?.gtin].colorKey ?? '-',
            }, matrixResponseData.value, groupBy.value === groupByBranch);
        }
    }

    if (!nosTabSelected.value) {
        updateMatrixTabQuantities();
    }

    updateSums();
    if (showRoundedUpAlert) {
        showToast('info', 'Matrix.rounded_flash', 'Matrix.rounded_flash_text');
    }
};

const resetClicked = () => {
    resetModalOpen.value = true;
};

const resetModalClicked = (index: number) => {
    resetModalOpen.value = false;
    if (!nosTabSelected.value && !validateOrderItems()) {
        return;
    }

    if (index === 1) {
        if (nosTabSelected.value) {
            resetMatrixNosQuantity()?.then((response) => {
                if (!response.error) {
                    showToast('success', 'Matrix.reset_matrix_success', 'Matrix.reset_matrix_success_text');
                }
            });
        } else {
            resetMatrixOrderItemQuantities(
                currentDeliveryDate.value?.value,
                matrixResponseData.value ?? undefined,
                groupBy.value === groupByBranch
            )?.then((response) => {
                if (!response.error) {
                    showToast('success', 'Matrix.reset_matrix_success', 'Matrix.reset_matrix_success_text');
                }
            });
        }
        updateMatrixContentData(true);
        updateSums();
    }
};
const orderRedirectClicked = (index: number) => {
    orderRedirectModalOpen.value = false;
    emit('close:closeMatrix');

    if (index !== 0) {
        // @ts-ignore: Todo Check type
        linkToOrder.value = navigateTo(`${NuxtLinkNameTypes.ORDER_HISTORY_PAGE}?searchText=${linkToOrder.value}&cartRedirect=true`);
    }
};

const onWindowResize = () => {
    if (!isTouchDevice()) {
        quickSelectionCount.value = 1;
    }
};

const updateCartId = () => {
    reloadData();
};

const noNeedToUpdateCart = () => {
    cartItemsNeedsToReload.value = false;
};

const cartItemsUpdated = () => {
    cartItemsNeedsToReload.value = true;
}

const reloadCartItems = async() => {
    if (!currentProductIdOrSku) {
        return;
    }

    await loadMatrixCartItems();
    updateSums();
    cartItemsNeedsToReload.value = false;
}

onMounted(async() => {
    if (!$hasRole(RoleTypes.SUPPLIER) && !cartsStore.activeCart) {
        await cartsStore.loadCarts();
    }

    $emitter.$on('updateCartId', updateCartId);

    updateScrollContainer();

    reloadData();
    $emitter.$on('update:updatingQuantities', updateQuantitiesResponse);
    $emitter.$on('cartItemsUpdated', cartItemsUpdated);
    $emitter.$on('reloadCartItems', reloadCartItems);
    $emitter.$on('noNeedToUpdateCart', noNeedToUpdateCart);
    $emitter.$on('updateTotalSums', updateSums);
    window.addEventListener('resize', onWindowResize);
    toasts.setToastClosedState(false);
});

const selectClientViewMode = () => {
    quickSelection.value = getQuickSelectionStorage() ?? true;
    groupBy.value =
        getGroupByKeyStorage() === null ?
            groupByBranch :
            getGroupByKeyStorage()!;
    groupByIndex.value = groupByValues.indexOf(groupBy.value);
};

const onFlyoutChanged = () => {
    reloadData();
};

const getDeadlineDateForActiveTab = computed(() => {
    const allDeliveryDates = getAllAvailableDeliveryDates();

    if (allDeliveryDates.length === 0) {
        return '';
    }

    const activeTabDate = new Date(deliveryDates.value[deliveryDateIndex.value].value);

    const matchedDeadlineDate = allDeliveryDates.find((date: MatrixAvailableDeliveryDate) => {
        const fromDateObj = new Date(date.fromDate);
        const toDateObj = new Date(date.toDate);

        return activeTabDate >= fromDateObj && activeTabDate <= toDateObj;
    })?.deadlineDate;

    return matchedDeadlineDate && $isStore(StoreTypes.VO) ? matchedDeadlineDate : '';
});

onBeforeMount(() => {
    selectClientViewMode();
    $emitter.$on('cartFlyoutChanged', onFlyoutChanged);
});

onUnmounted(() => {
    $emitter.$off('updateCartId', updateCartId);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', onWindowResize);
    $emitter.$off('cartFlyoutChanged', onFlyoutChanged);
    $emitter.$off('update:updatingQuantities', updateQuantitiesResponse);
    $emitter.$off('updateOrderItemInCarts', updateItemInCarts);
    $emitter.$off('cartItemsUpdated', cartItemsUpdated);
    $emitter.$off('noNeedToUpdateCart', noNeedToUpdateCart);
    $emitter.$off('reloadCartItems', reloadCartItems);
    $emitter.$off('updateTotalSums', updateSums);
    matrixClosed();
});
</script>

<style lang="scss" scoped>
.content {
    text-align: left;
    position: relative;
    padding: 0 sp(xs);
    min-width: $setting-grid-max-width;
    max-width: 100%;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
        appearance: none;
        width: rem(7);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgb(0 0 0 / 50%);
        box-shadow: 0 0 1px rgb(255 255 255 / 50%);
    }
}

.cluster-loading {
    >:not(.matrix-spinner) {
        display: none;
    }
}

.validerror {
    width: 100%;
    padding: rem(2) rem(20);
    font-size: fs(small);
    margin-bottom: rem(10);
    text-align: left;

    @include helper-color-bg(alert-info);
    @include helper-color(white);
}

.matrix-disabled {
    pointer-events: none;
    cursor: default;
}

.matrix-disabled-columns {
    pointer-events: auto;
    cursor: default;
    opacity: 0.75;
}

.matrix-submitting-data {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
}

:deep(.sf-accordion-item__header) {
    @include helper-color(corporate-blue);
}

:deep(.sf-chevron__bar::after) {
    @include helper-color-bg(corporate-blue);
}

.accordion-border-top,
.accordion-border-bottom {
    width: 100%;

    @include helper-border($setting-color-gray-1, rem(1));
}

.accordion-border-top {
    margin-top: rem(30);
}

.accordion-border-bottom {
    margin-bottom: rem(30);
}

.matrix-spinner {
    height: 75vh;
}

.detail-tabs {
    padding: 1rem;
}

.tabs-wrapper {
    display: flex;
    flex-direction: column;

    .tabs {
        padding: 1rem 0;
    }
}
</style>
