 
import moment from 'moment';
import type {
    BranchDeliveries,
    MatrixData
} from '~/composables/types/api/searchDiscover/getMatrix';
import { useBranchClusterStore } from '~/composables/stores/useBranchClusterStore';
import { useMinQuantityWarningsStore } from '~/composables/stores/useMinQuantityWarningsStore';

export interface MatrixWarnings {
    [key: string]: {
        [key: string]: {
            key: string;
            translationKey: string;
            quantity: number;
            minQuantity: number;
        }[];
    };
}

export default function useMatrixWarnings() {
    const toasts = useToasts();
    const { $t } = useNuxtApp();
    const warningsStore = useMinQuantityWarningsStore();
    const branchClusterStore = useBranchClusterStore();
    let branchClustersActive = false;

    const showMinQuantitiesWarning = () => {
        if (!toasts.getToastClosedState() && Object.keys(warningsStore.get()).length === 0 && !branchClustersActive) {
            toasts.add({
                type: 'warning',
                headline: $t('Matrix.min_quantity_matrix_close_warning_head'),
                text: $t('Matrix.min_quantity_matrix_close_warning'),
                autoHide: false,
                allowReopen: false,
            });
        }
    };

    const checkMinQuantities = (matrixResponseData: MatrixData, quantitiesByBranches: BranchDeliveries, groupByBranch: boolean): boolean => {
        const warnings: MatrixWarnings = {};
        const filteredQuantities = { ...quantitiesByBranches };
        const today = moment().startOf('day');
        branchClustersActive = Boolean(matrixResponseData?.items?.branchCluster && matrixResponseData.items.branchCluster !== '-1');

        for (const [key, value] of Object.entries(filteredQuantities)) {
            filteredQuantities[key] = value.filter((quantity) => moment(quantity.deliveryDate, 'YYYY-MM-DD').isSameOrAfter(today));
        }

        const getTotalOverBranches = (excludeBranchId: string, colorKey?: string): number => matrixResponseData.branches.reduce((total, branchId) => {
            if (branchId === excludeBranchId) {
                return total;
            }

            const quantities = quantitiesByBranches[branchId] ?? [];
            if (colorKey) {
                return total + quantities
                    .filter((quantity) => quantity.quantity !== 0 && quantity.colorKey === colorKey)
                    .reduce((sum, quantity) => sum + (quantity?.quantity ?? 0), 0);
            }

            return total + quantities
                .filter((quantity) => quantity.quantity !== 0)
                .reduce((sum, quantity) => sum + (quantity?.quantity ?? 0), 0);
        }, 0);

        const overwriteBranchesWithSelectedCluster = (): string[] => {
            const getBranchClusterStore = branchClusterStore.get();
            if (getBranchClusterStore) {
                return getBranchClusterStore.clusters.flatMap(cluster => cluster.branches);
            }

            return matrixResponseData.branches;
        };

        const getAbstractMinQuantityWarnings = () => {
            if (!matrixResponseData) {
                return;
            }

            const { colors, sizes, minQuantityAbstract: min } = matrixResponseData;
            const product = matrixResponseData?.concreteProductsBySizeAndColor?.[sizes[0].key]?.[colors[0].key];

            if (min > 1 && product?.minQtyColor === 1 && product?.customerGroupAccess) {
                const selectedBranches = overwriteBranchesWithSelectedCluster();

                selectedBranches.forEach(branchId => {
                    const total = Object.values(quantitiesByBranches).flat().reduce((sum, quantity) => sum + (quantity.quantity ?? 0), 0);
                    const branchQuantities = (quantitiesByBranches[branchId] ?? []).reduce((sum, quantity) => sum + (quantity?.quantity ?? 0), 0);

                    if (total > 0 && total < min && branchQuantities > 0) {
                        if (groupByBranch) {
                            warnings[branchId] = warnings[branchId] || {};
                            warnings[branchId].section = warnings[branchId].section || [];
                            warnings[branchId].section.push({
                                key: `${branchId}-section`,
                                translationKey: 'Matrix.min_quantity_abstract_warning',
                                quantity: total,
                                minQuantity: min,
                            });
                        } else {
                            colors.forEach(color => {
                                warnings[color.key] = warnings[color.key] || {};
                                warnings[color.key][branchId] = warnings[color.key][branchId] || [];
                                warnings[color.key][branchId].push({
                                    key: `${branchId}-${color.key}`,
                                    translationKey: 'Matrix.min_quantity_abstract_warning',
                                    quantity: total,
                                    minQuantity: min,
                                });
                            });
                        }
                    }
                });
            }
        };

        const getColorMinQuantityWarnings = () => {
            const { minQuantityAbstract: minAbstract, colors, sizes } = matrixResponseData;
            const branches = overwriteBranchesWithSelectedCluster();

            branches.forEach(branchId => {
                const quantities = quantitiesByBranches[branchId] ?? [];

                colors.forEach(color => {
                    let total = 0;
                    let min = 0;
                    let sizesIndex = 0;

                    sizes.forEach(size => {
                        const product = matrixResponseData?.concreteProductsBySizeAndColor?.[size.key]?.[color.key];
                        if (!product) {
                            return;
                        }

                        min = product?.minQtyColor && product?.minQtyColor > 1 ? product?.minQtyColor : 1;
                        if (min === 1 || !product?.customerGroupAccess) {
                            total = 0;

                            return;
                        }

                        total += quantities.filter(quantity => quantity?.gtin === product?.gtin)
                            .reduce((sum, quantity) => sum + (quantity?.quantity ?? 0), 0);

                        if (total !== 0 && sizesIndex < 1) {
                            total += getTotalOverBranches(branchId, product.colorKey || '-');
                            sizesIndex++;
                        }
                    });

                    if (total > 0 && total < min) {
                        const sectionKey = groupByBranch ? branchId : color.key;
                        const rowKey = groupByBranch ? color.key : branchId;

                        warnings[sectionKey] = warnings[sectionKey] || {};
                        warnings[sectionKey][rowKey] = warnings[sectionKey][rowKey] || [];
                        warnings[sectionKey][rowKey].push({
                            key: `${branchId}-${color.key}`,
                            translationKey: minAbstract === 1 ? 'Matrix.min_quantity_color_warning' : 'Matrix.min_quantity_color_warning_branch',
                            quantity: total,
                            minQuantity: min,
                        });
                    }
                });
            });
        };

        getAbstractMinQuantityWarnings();
        getColorMinQuantityWarnings();

        if (Object.keys(warnings).length > 0) {
            showMinQuantitiesWarning();
            warningsStore.set(warnings);

            return false;
        }
        toasts.clear('warning');
        warningsStore.set({});

        return true;
    };

    const nosNotAvailableWarning = () => {
        if (toasts.toasts.length > 0) {
            toasts.clear('warning');
        }
        toasts.add({
            type: 'error',
            headline: $t('Matrix.matrix_nos_loading_issue_headline'),
            text: $t('Matrix.matrix_nos_loading_issue'),
        });
    };

    return {
        checkMinQuantities,
        showMinQuantitiesWarning,
        nosNotAvailableWarning
    };
}
