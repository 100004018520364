<template>
    <div>
        <div :class="classes">
            <AtomButton v-if="sideButtons" :disabled="isMin || disabled" icon="minus" :size="size" @click="decrement" />
            <div :class="{ 'number-input__input-container': true }">
                <div v-if="currencySymbol" class="currency-icon">€</div>
                <input
                    ref="inputElement"
                    :type="inputType"
                    :disabled="disabled"
                    :value="stringValue.replace('.',',')"
                    :name="name"
                    onfocus="this.select();"
                    autocomplete="off"
                    @input="onInput"
                    @blur="onBlur"
                    @keydown="onKeyDown"
                    @keyup="onKeyUp"
                >
                <div v-if="!sideButtons" class="number-input__arrows">
                    <div :class="{ disabled: isMax || disabled }" @click="increment">
                        <AtomIcon name="chevron-up" />
                    </div>
                    <div :class="{ disabled: isMin || disabled }" @click="decrement">
                        <AtomIcon name="chevron-down" />
                    </div>
                </div>
            </div>
            <AtomButton v-if="sideButtons" :disabled="isMax || disabled" icon="plus" :size="size" @click="increment" />
        </div>
        <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
        </div>
        <div v-else-if="inputWarning" class="error-message">
            {{ inputWarning }}
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useNuxtApp } from '#app';

const { $t } = useNuxtApp();
const TWO = 2;
const TEN = 10;

const props = withDefaults(defineProps<{
    inputType?: string,
    modelValue?: number,
    precision?: number,
    name?: string,
    step?: number,
    size?: 'l' | 'm' | 's',
    autoFocus?: boolean,
    sideButtons?: boolean,
    min?: number,
    max?: number,
    allowZero?: boolean,
    allowUndefined?: boolean,
    disabled?: boolean,
    errorMessage?: string,
    maxLength?: number,
    currencySymbol?: boolean,
}>(), {
    inputType: 'text',
    precision: 0,
    step: 1,
    name: 'number-input',
    size: 'l',
    autoFocus: false,
    sideButtons: false,
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER,
    allowZero: false,
    allowUndefined: false,
    disabled: false,
    currencySymbol: false,
    modelValue: undefined,
    errorMessage: undefined,
    maxLength: undefined,
});

const emit = defineEmits<{
    (e: 'onEnter' | 'onTab', event: KeyboardEvent): void;
    (e: 'update:modelValue', value: number | string | undefined): void;
}>();

const inputElement = ref<HTMLElement | null>(null);

const clamp = (value: number) => (props.allowZero && value === 0 ? 0 : Math.min(props.max, Math.max(props.min, value)));

const initStringInputValue = (): string => {
    if (props.allowUndefined && typeof props.modelValue !== 'number') {
        return '';
    }

    return `${props.modelValue?.toFixed(props.precision)}`;
};

const stringValue = ref(initStringInputValue());

const correctedStringValue = computed(() => {
    if (!stringValue.value) {
        return '';
    }
    if (stringValue.value[0] === '.' || stringValue.value[0] === ',') {
        return `0${stringValue.value}`;
    }
    return stringValue.value;
});

const numberValue = computed(() => {
    if (!correctedStringValue.value) {
        return clamp(0);
    }
    return clamp(parseFloat(correctedStringValue.value.replace(',', '.')));
});

const actualMin = computed(() => (props.min < 0 || !props.allowZero ? props.min : 0));
const actualMax = computed(() => (props.max > 0 || !props.allowZero ? props.max : 0));
const isMin = computed(() => numberValue.value === actualMin.value);
const isMax = computed(() => numberValue.value === actualMax.value);
const inputWarning: Ref<string | null> = ref(null);

const classes = computed(() => {
    const numberInput = `${props.size} number-input`;
    const buttons = `${props.sideButtons ? 'side-buttons' : ''}`;
    const error = `${props.errorMessage ? 'error' : ''}`;
    const warning = `${inputWarning.value ? 'error' : ''}`;

    return `${numberInput} ${buttons} ${error} ${warning}`;
});

const getRegex = () => {

    const precision = props.precision > 0 ? `([.,]\\d{0,${props.precision}})?` : '';

    return props.maxLength ? `^\\d{0,${props.maxLength - props.precision}}${precision}$` : '';
};

const onKeyDown = (event: KeyboardEvent) => {
    if (/mac/i.test(navigator.platform) ? event.metaKey : event.ctrlKey) {
        return;
    }
    if (inputElement.value === null) {
        return;
    }

    if (event.key === 'Dead') {
        event.preventDefault();
        inputElement.value.blur();
        setTimeout(() => inputElement.value!.focus());
    } else if (event.code === 'Tab') {
        emit('onTab', event);
    } else if (props.precision <= 0 && event.code === 'Period') {
        event.preventDefault();
    } else if (props.precision > 0 && event.code === 'Period' && stringValue.value.includes('.')) {
        event.preventDefault();
    } else if (
        event.code !== 'Backspace' &&
        event.code !== 'Enter' &&
        event.code !== 'NumpadEnter' &&
        event.code !== 'Delete' &&
        event.code !== 'ArrowLeft' &&
        event.code !== 'ArrowRight' &&
        event.code !== 'ArrowUp' &&
        event.code !== 'ArrowDown' &&
        event.code !== 'Tab' &&
        event.code !== 'Period' &&
        !/[0-9,]/.test(event.key)
    ) {
        event.preventDefault();
    }
};

const sanitize = (value: number, up: boolean) => {
    if (isNaN(value)) {
        return clamp(0);
    }
    // float inprecisions will not make this work
    if (props.precision === 0) {
        const mod = value % props.step;
        if (mod !== 0) {
            if (up) {
                return clamp(value + props.step - mod);
            }

            return clamp(value - mod);
        }
    }

    return value;
};

const checkInputEndsWithComa = (input: string) => {
    if (props.precision > 0) {
        return /[,.]0*$|[,.]\d*0+$/.test(input);
    }

    return false;
};

const getInputWithPrecisionValue = (stringInput: string): string => parseFloat(
    stringInput.replace(',', '.')
).toFixed(props.precision).toString();

const onBlur = () => {
    if (props.precision > 0) {
        stringValue.value = getInputWithPrecisionValue(stringValue.value);

        if (!props.allowZero && parseFloat(stringValue.value) === 0) {
            stringValue.value = '';
        } else if (isNaN(parseFloat(stringValue.value))) {
            stringValue.value = '';
        }

        emit('update:modelValue', stringValue.value);
    }
};

const updateModel = () => {
    const numberUpdate = props.precision > 0 ? numberValue.value.toFixed(props.precision).toString() : numberValue.value;
    const stringUpdate = props.precision > 0 ? getInputWithPrecisionValue(stringValue.value) : stringValue.value;
    if (props.allowUndefined && !stringValue.value) {
        emit('update:modelValue', undefined);
    } else if (props.precision > 0) {
        if (!checkInputEndsWithComa(stringValue.value)) {
            emit('update:modelValue', numberUpdate);
        } else {
            emit('update:modelValue', stringUpdate);
        }
    } else {
        emit('update:modelValue', numberUpdate);
    }
};

// fixes fractional errors happening when adding/subtracting lower fractions for example 0.05 + 0.01
const safeAdd = (left: number, right: number) => {
    if (props.precision === 0) {
        return left + right;
    }

    const multiplier = Math.pow(TEN, props.precision);

    return Math.round((left * multiplier) + (right * multiplier)) / multiplier;
};

const checkButtonInputs = (inputValue: string) => {
    const regex = new RegExp(getRegex());

    if (regex.test(inputValue)) {
        stringValue.value = inputValue;
    }
};

const increment = (event: Event) => {
    if (props.disabled || isMax.value || inputElement.value === null) {
        return;
    }

    event.preventDefault();
    event.stopPropagation();
    let inputValue = '';
    if (props.allowZero && numberValue.value === 0 && props.min > 0) {
        inputValue = `${props.min}`;
    } else if (props.allowZero && props.max < 0 && props.max === numberValue.value) {
        inputValue = '0';
    } else {
        const currentSafeValue = sanitize(numberValue.value, false);
        const forceTwoSteps = !props.allowZero && safeAdd(currentSafeValue, props.step) === 0;

        inputValue = `${clamp(safeAdd(currentSafeValue, props.step * (forceTwoSteps ? TWO : 1)))}`;
    }
    checkButtonInputs(props.precision > 0 ? getInputWithPrecisionValue(inputValue) : inputValue);
    inputElement.value.focus();
    updateModel();
};

const decrement = (event: Event) => {
    if (props.disabled || isMin.value || inputElement.value === null) {
        return;
    }
    event.preventDefault();
    event.stopPropagation();
    let inputValue = '';
    if (props.allowZero && numberValue.value === 0 && props.max < 0) {
        inputValue = `${props.max}`;
    } else if (props.allowZero && props.min > 0 && props.min === numberValue.value) {
        inputValue = '0';
    } else {
        const currentSafeValue = sanitize(numberValue.value, true);
        const forceTwoSteps = !props.allowZero && safeAdd(currentSafeValue, -props.step) === 0;

        inputValue = `${clamp(safeAdd(currentSafeValue, -props.step * (forceTwoSteps ? TWO : 1)))}`;
    }
    checkButtonInputs(props.precision > 0 ? getInputWithPrecisionValue(inputValue) : inputValue);
    inputElement.value.focus();
    updateModel();
};

const onKeyUp = (event: KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        emit('onEnter', event);
    } else if (event.code === 'ArrowUp') {
        increment(event);
    } else if (event.code === 'ArrowDown') {
        decrement(event);
    }
};

const onInput = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const regex = new RegExp(getRegex());

    if (regex.test(target.value)) {
        inputWarning.value = null;
        stringValue.value = target.value;
        updateModel();

        return;
    }

    if (!/[.,]/.test(target.value) && props.maxLength && target.value.length > props.maxLength) {
        target.value = target.value.substring(0, props.maxLength);
        stringValue.value = target.value;
        updateModel();

        return;
    }

    if (!regex.test(stringValue.value)) {
        inputWarning.value = $t('General.invalid_input');
    }

    stringValue.value = stringValue.value.replace('.', ',');
    target.value = stringValue.value;
};

if (props.autoFocus) {
    onMounted(() => {
        inputElement.value?.focus();
    });
}

watch(() => props.modelValue, (newValue) => {
    if (props.allowZero && Number(newValue) === 0) {
        if (props.precision > 0) {
            let zero = '0,';
            for (let i = 0; i < props.precision; i++) {
                zero += '0';
            }
            stringValue.value = zero;
        } else {
            stringValue.value = '0';
        }
    } else if (newValue === 0 || (props.min === 0 && newValue && newValue < 0)) {
        stringValue.value = '';
    } else {
        stringValue.value = newValue?.toString() ?? '';
    }
});

</script>

<style lang="scss" scoped>
.number-input {
    display: flex;

    .number-input__arrows span {
        display: block;
    }

    &.l {
        height: rem(48);

        .number-input__arrows {
            @include helper-svg-size(16);

            div {
                height: rem(28);
            }
        }
    }

    &.m {
        height: rem(40);

        .number-input__arrows {
            @include helper-svg-size(18);

            div {
                height: rem(22);
            }
        }
    }

    &.s {
        height: rem(32);

        .number-input__arrows {
            @include helper-svg-size(14);

            div {
                height: rem(16);
            }
        }
    }

    input {
        width: 100%;
        height: 100%;
        appearance: textfield;
        text-align: center;

        @include helper-border();
        @include helper-border-radius($setting-border-radius-input);
        @include helper-color(text-title);
    }

    &__input-container {
        position: relative;
    }

    &__arrows {
        user-select: none;
        cursor: pointer;
        position: absolute;
        height: 100%;
        right: sp(xxs);
        top: 0;
        margin: auto;

        .disabled {
            @include helper-color(state-disabled);
        }
    }

    &.error input {
        @include helper-border($setting-color-alert-danger, rem(1));
    }

    button {
        margin: 0 sp(xs);
    }
}

.error-message {
    margin-top: sp(xxs);

    @include helper-color(alert-danger);
    @include helper-font-size(smaller);
    @include helper-font-line-height(tight);
}

.side-buttons {
    input {
        width: rem(80);
    }
}

:deep(.required-input) {
    @include helper-border($setting-color-state-invalid, rem(1));
    @include helper-border-radius($setting-border-radius-input);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

input[type='number'] {
    appearance: textfield;
}

.currency-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;

    @include helper-font-size(6);
    @include helper-font-line-height(4);
    @include helper-font-weight(medium);
}
</style>
