<template>
    <div v-if="branches && branches.length > 0" class="clusters-info" title="">
        <div v-if="!icon" class="clusters-info-branch">
            <span>{{branches.length}}</span>
            <span v-if="branches.length > 1">{{ $t('General.branches') }}</span>
            <span v-else>{{ $t('General.branch') }}</span>
            <AtomIcon
                name="info"
                bolding="2"
                @mouseenter="mouseEnterInfo($event)"
                @mouseleave="mouseLeaveInfo"
            />
        </div>
        <div v-if="icon" class="clusters-info-color">
            <AtomIcon
                name="info"
                bolding="2"
                @mouseenter="mouseEnterInfo($event)"
                @mouseleave="mouseLeaveInfo"
            />
        </div>
    </div>
    <AtomTooltip
        class="clusters-info__tooltip"
        :element="tooltipElement"
        :offset-x="!icon ? 35 : 25"
        :offset-y="-10"
    >
        <div class="clusters-info__cluster-container">
            <div v-for="(col, colIndex) in dividedColumns" :key="colIndex">
                <div v-for="(row, rowIndex) in col" :key="rowIndex">
                    <span class="cluster-row">{{ row }}</span>
                </div>
            </div>
        </div>
    </AtomTooltip>
</template>

<script lang="ts" setup>

const props = withDefaults(defineProps<{
    branches: string[] | null,
    branchNames: string[] | null,
    icon?: boolean,
}>(), {
    icon: false
});

const tooltipInfo = ref('');
const tooltipElement = ref<HTMLElement | null>(null);
const dividedColumns = ref<string[][]>([]);

const setDividedColumns = () => {
    dividedColumns.value = [];
    let branchesArr = props.branches ?? [];
    const branchNamesArr = props.branchNames ?? [];
    branchesArr = branchesArr.map((branch, index) => `${branch} ${branchNamesArr[index] ?? ''}`);
    for (let i = 0; i < branchesArr.length; i += 10) {
        dividedColumns.value.push(branchesArr.slice(i, i + 10));
    }
};

const mouseEnterInfo = (event: MouseEvent) => {
    setDividedColumns();
    tooltipInfo.value = props.branches?.toString().replace(',', ' ') ?? '';
    tooltipElement.value = event.target as HTMLElement;
};

const mouseLeaveInfo = () => {
    tooltipElement.value = null;
};
</script>

<style lang="scss" scoped>
.clusters-info {
    z-index: $setting-zi-icon;

    &-branch {
        display: flex;
        align-items: center;
        gap: sp(xxs);
        padding: rem(2) rem(12);

        @include helper-color(corporate-blue);
        @include helper-border($setting-color-corporate-blue);
        @include helper-border-radius(rem(18));
    }

    &-color {
        margin-left: auto;

        @include helper-color(corporate-blue);
    }

    &__cluster-container {
        display: flex;
        flex-direction: row;
        gap: sp(xs);
    }
}
</style>
