<template>
    <div class="branch-cluster options__container-wrapper spacing-right--s">
        <div class="options__container-headline flex grid--middle spacing-bottom--inner spacing-bottom--xxs">
            {{$t('Matrix.branch_cluster')}}
            <AtomToggle
                v-model="currentToggleState"
                :disabled="cartClosed"
                @update:model-value="updateBranchCluster"
            />
        </div>
        <div class="options__item">
            <AtomSelect
                class="select"
                name="pageSelect"
                :model-value="activeOption"
                :disabled="!currentToggleState || cartClosed"
                :options="options"
                @update:model-value="applyBranchCluster"
            />
        </div>
    </div>
    <AtomModal
        :class="{'branch-cluster-modal': true, 'close-button-only': moduleItems.length}"
        :open="!getClusterState()"
        :buttons="modalButtons"
        :title="$t('Matrix.branch_classes_not_compatible')"
        :text="moduleItems.length ? $t('Matrix.branch_classes_not_compatible_text_modules') : $t('Matrix.branch_classes_not_compatible_text')"
        :show-x-button="false"
        @update:open="resetToLastIndex"
        @button-clicked="buttonClicked"
    />
</template>

<script lang="ts" setup>
import type { ModalButton, SelectOption } from '~/composables/types/ui';
import type { MatrixModuleItem, MatrixOrderItem } from '~/composables/types/api/cartConditions/matrix';

const props = defineProps<{
    productAbstractId: string | null,
    branchClusterSelected: string | null
    cartClosed: boolean,
    orderItems: MatrixOrderItem[]
    moduleItems: MatrixModuleItem[]
}>();

const options: Ref<SelectOption[]> = ref([]);
const oldActiveOption: Ref<number> = ref(0);
const initialClusterSetup: Ref<boolean> = ref(false);
const activeOption: Ref<number> = ref(0);
const { $t } = useNuxtApp();

const modalButtons: ModalButton[] = !props.moduleItems.length ?
    [{
        text: $t('General.cancel'),
        type: 'secondary',
    },
    {
        text: $t('Matrix.reset_cluster'),
        type: 'primary',
    }] :
    [{
        text: $t('General.close'),
        type: 'primary',
    }];

const {
    branchClusterData,
    loadBranchClusters,
    updateBranchClusterById,
    getClusterState,
    setClusterState,
} = useBranchClusters(props.productAbstractId);

let {
    clusterToggleActive,
} = useBranchClusterStore();

const currentToggleState = ref(false);

const applyBranchCluster = (index: string | number | null | undefined, resetData: boolean = false) => {
    oldActiveOption.value = activeOption.value;

    if (isNaN(Number(index))) {
        updateBranchClusterById(null, resetData, initialClusterSetup.value);
        activeOption.value = Number(0);

        return;
    }

    updateBranchClusterById(Number(index), resetData, initialClusterSetup.value);
    activeOption.value = Number(index);
    initialClusterSetup.value = false;
};

const resetToLastIndex = () => {
    activeOption.value = oldActiveOption.value;
    if (!props.cartClosed) {
        applyBranchCluster(activeOption.value);
    }
    setClusterState(true);
};

const buttonClicked = (index: number) => {
    if (index === 0) {
        resetToLastIndex();

        return;
    }

    applyBranchCluster(activeOption.value, true);
    setClusterState(true);
};

const setSelectableOptions = () => {
    if (clusterToggleActive && branchClusterData.value) {
        options.value = branchClusterData.value.branchClusters?.map(cluster => (
            {
                name: cluster.branchClusterName,
                value: cluster.branchClusterId
            }
        )) ?? [];
    }
};

const resetBranchCluster = () => {
    currentToggleState.value = false;
    options.value = [];
    activeOption.value = 0;
    applyBranchCluster(null);
};

const updateBranchCluster = async(active?: boolean, initialLoad: boolean = false) => {
    let cluster = false;

    if (typeof active !== 'undefined') {
        clusterToggleActive = active;
    }

    if (props.orderItems.length && props.branchClusterSelected !== '-1' && !initialLoad) {
        resetBranchCluster();

        return;
    }

    if (clusterToggleActive) {
        cluster = await loadBranchClusters(initialLoad);
        currentToggleState.value = true;
    }

    if (!cluster || !branchClusterData.value?.branchClusters?.length) {
        resetBranchCluster();

        return;
    }

    setSelectableOptions();

    initialClusterSetup.value = true;
    const selectedCluster = Number(
        !props.branchClusterSelected ? branchClusterData.value.branchClusters[0].branchClusterId : props.branchClusterSelected
    );

    applyBranchCluster(selectedCluster, false);
};

onMounted(async() => {
    oldActiveOption.value = 0;
    activeOption.value = 0;

    if (props.branchClusterSelected !== '-1') {
        currentToggleState.value = Boolean(props.branchClusterSelected || !props.orderItems.length);
        clusterToggleActive = true;
    } else {
        clusterToggleActive = false;
    }

    await updateBranchCluster(undefined, true);
});
</script>

<style lang="scss" scoped>
    .branch-cluster {
        .selected.value-selected,
        .select {
            height: rem(50);
        }

        :deep(.modal-box__close) {
            display: flex;
        }
    }

    .close-button-only {
        :deep(.modal-box__buttons) {
            justify-content: flex-start;
        }
    }
</style>
