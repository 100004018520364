import type {
    BranchCluster
} from '~/composables/types/api/searchDiscover/getBranchClusters';

export type BranchClusterState = {
    branchClusterTemplate: {
        resetData: boolean,
        initialClusterSetup: boolean,
        branchClusterId: number,
        branchClusterName: string,
        clusters: BranchCluster[]
    } | null,
    clusterActive: boolean
    clusterToggleActive: boolean
    branchClusterLoading: boolean | null
}

type BranchClusterActions = {
    update: (branchClusterTemplate: BranchClusterState['branchClusterTemplate']) => void,
    remove: () => void,
    get: () => BranchClusterState['branchClusterTemplate'],
    setClusterState: (active: boolean) => void,
    setClusterLoadingState: (branchClusterLoading: boolean | null) => void
    getClusterLoadingState: () => boolean | null
    getClusterState: () => boolean,
    getClusterCount: (clusterName?: string) => number,
}

export const useBranchClusterStore = defineStore<string, BranchClusterState, any, BranchClusterActions>('branchCluster', {
    state: () => ({
        branchClusterTemplate: null,
        clusterActive: true,
        clusterToggleActive: false,
        branchClusterLoading: null,
    }),
    actions: {
        update(branchClusterTemplate) {
            if (branchClusterTemplate === null) {
                this.branchClusterTemplate = null;

                return;
            }

            if (typeof branchClusterTemplate?.resetData !== 'undefined') {
                this.branchClusterTemplate = {
                    ...branchClusterTemplate
                };
            }
        },
        remove() {
            this.branchClusterTemplate = null;
        },
        get() {
            return this.branchClusterTemplate;
        },
        setClusterState(active) {
            this.clusterActive = active;
        },
        getClusterState() {
            return this.clusterActive;
        },
        setClusterLoadingState(currentState) {
            this.branchClusterLoading = currentState;
        },
        getClusterLoadingState() {
            return this.branchClusterLoading;
        },
        getClusterCount(clusterName?: string) {
            let count = 1;

            if (this.branchClusterTemplate && clusterName) {
                const clusterIndex = this.branchClusterTemplate.clusters.findIndex(cluster => cluster.name === clusterName);
                count = this.branchClusterTemplate.clusters?.[clusterIndex]?.branches?.length ?? 1;
            }

            return count;
        }
    },
});
