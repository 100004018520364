import type { MatrixUpdateCartItem } from '~/composables/api/cartConditions/updateMatrix';
import updateMatrix from '~/composables/api/cartConditions/updateMatrix';
import clearMatrix from '~/composables/api/cartConditions/clearMatrix';
import type { PromiseResponseData } from '~/composables/types/api/apiResponse';
import useMatrixDateHelper from '~/composables/matrix/useMatrixDateHelper';
import type {
    BranchDeliveries,
    EnrichedMatrixDataSet,
    MatrixData
} from '~/composables/types/api/searchDiscover/getMatrix';
import type {
    MatrixModuleItem,
    MatrixOrderItem
} from '~/composables/types/api/cartConditions/matrix';
import type {
    BranchClusterState} from '~/composables/stores/useBranchClusterStore';
import {
    useBranchClusterStore
} from '~/composables/stores/useBranchClusterStore';
import type { BranchCluster } from '~/composables/types/api/searchDiscover/getBranchClusters';
import useMatrixWarnings from '~/composables/matrix/useMatrixWarnings';

export type CartKey = {
    gtin: string,
    branchId: string,
    deliveryDate?: string,
    validFrom?: string,
    colorKey?: string,
}

export type MatrixStoredOrderItem = CartKey & {
    quantity?: number,
    minStock?: number,
    storedQuantity?: number,
    validTo?: string,
}

export type NosKey = {
    gtin: string,
    branchId: string,
    validFrom?: string,
    validTo?: string,
    colorKey?: string,
}

export type MatrixNosItem = NosKey & {
    minStock?: number,
    standardStock?: number,
    storedQuantity?: number,
    validTo?: string,
    writable: boolean,
    releasedAt: string | null,
}

type MatrixModuleItemToDelete = {
    branchId: string,
    gtin: string,
    quantity: number,
}

export type MatrixModuleItemValidation = {
    items: MatrixModuleItemToDelete[],
    deliveryDate: string,
    increase: boolean,
}

type OrderItemsAfterUpdate = {
    [p: string]: number,
}

type DateItems = {
    fromDate: string,
    toDate: string,
    deadlineDate: string,
}

type ItemWithDeliveryDate = {
    deliveryDate?: string,
    gtin: string,
    branchId: string,
    quantity?: number,
}

const updateDelay = 1000;

const createCartKey = (values: CartKey): string => `${values.gtin}-${values.branchId}-${values.deliveryDate}`;
const createModuleKey = (values: Omit<CartKey, 'deliveryDate' & 'validFrom'>): string => `${values.gtin}-${values.branchId}`;
const createModuleValidationKey = (branchId: string, colorKey: string): string => `${branchId}-${colorKey}`;

const getUpdateItem = (item: MatrixStoredOrderItem, matrixData: MatrixData) => {
    const now = useMatrixDateHelper().createDateString();
    const deliveryDate = item.deliveryDate ?? '';

    let found = true;
    let validDeliveryDates = matrixData.availableDeliveryDatesByGtin[item.gtin].find(
        (date: DateItems) => date.fromDate <= deliveryDate && date.toDate >= deliveryDate && date.deadlineDate >= now,
    );

    if (!validDeliveryDates) {
        found = false;
        validDeliveryDates = matrixData.availableDeliveryDatesByGtin[item.gtin].find(
            (date: DateItems) => date.deadlineDate >= now,
        );
    }

    return {
        gtin: item.gtin,
        branchId: item.branchId,
        deliveryDate: found || item.quantity === 0 ? item.deliveryDate : validDeliveryDates?.fromDate,
        quantity: item.quantity,
        orderDeadline: validDeliveryDates?.deadlineDate,
    };
};

// eslint-disable-next-line max-lines-per-function
export default function useMatrixOrderItems(errorHandler: (message: string) => void, cartUuid: Ref<string | null>) {
    const { $emitter } = useNuxtApp();
    const cartsStore = useCartsStore();
    const {
        checkMinQuantities,
    } = useMatrixWarnings();
    const warningsStore = useMinQuantityWarningsStore();
    const { get: getBranchCluster, setClusterState, getClusterState } = useBranchClusterStore();
    let matrixData: MatrixData;
    let orderItems: { [index: string]: MatrixStoredOrderItem } = {};
    let moduleItems: { [index: string]: MatrixModuleItem } = {};
    let timeout: number | undefined;
    let updatePromise: PromiseResponseData | null;
    let quantitiesUpdated = false;
    let reportMatrixClosedAfterRequest = false;
    let userFlowStateChanged = false;
    let netPricesChanged = false;
    let storedCartUuid = cartsStore.activeCart?.id;
    const quantitiesSubmitted: Ref<boolean> = ref(true);
    const minQuantitiesAchieved = computed(() => Object.keys(warningsStore.get()).length <= 0);

    const needsUpdate = () => Object.values(orderItems).some(item => item.storedQuantity !== item.quantity);

    const getColorIdByGtin = (gtin: string): string => matrixData.concreteProductsByGtin?.[gtin]?.colorKey || '-';

    const updateUserflowToggleState = (changed: boolean) => {
        userFlowStateChanged = changed;
    };

    const updateNetPricesChangedState = (changed: boolean) => {
        netPricesChanged = changed;
    };

    const getCartQuantitiesByBranches = () => {
        const result: BranchDeliveries = {};
        for (const item of Object.values(orderItems)) {
            result[item.branchId] ??= [];
            result[item.branchId].push(item);
        }

        return result;
    };

    const matrixClosed = () => {
        if (timeout || updatePromise) {
            reportMatrixClosedAfterRequest = true;

            return;
        }
        if (!userFlowStateChanged && !quantitiesUpdated && !netPricesChanged) {
            reportMatrixClosedAfterRequest = true;

            return;
        }
        if (matrixData) {
            const detail = {
                cartId: storedCartUuid,
                catalogId: matrixData!.catalogId,
                brandCode: matrixData!.brandCode,
                modelCode: matrixData!.modelNumberFrontend,
                sapModelCode: matrixData!.modelNumber,
                quantitiesUpdated: quantitiesUpdated || userFlowStateChanged || netPricesChanged,
            };

            const event = new CustomEvent('matrixClosed', { detail });
            window.dispatchEvent(event);
        }
    };

    const backendUpdatedHandler = (response: any) => {
        updatePromise = null;
        if (response.error) {
            errorHandler(response);
        } else {
            quantitiesUpdated = true;

            $emitter.$emit('update:updatingQuantities', true);
            if (reportMatrixClosedAfterRequest) {
                matrixClosed();
            }
        }
    };

    const stopTimeout = () => {
        if (timeout !== undefined) {
            clearTimeout(timeout);
            timeout = undefined;

            window.removeEventListener('beforeunload', onBeforeUnload);
        }
    };

    const declusterBranchItems = (items: MatrixUpdateCartItem[]): MatrixUpdateCartItem[] => {
        const branchClusterStore = getBranchCluster();
        if (!matrixData.items.branchCluster || !branchClusterStore) {
            return items;
        }

        const activeCluster = branchClusterStore.clusters;

        const declusteredItems: MatrixUpdateCartItem[] = [];

        items.map(item => {
            const branches = activeCluster.find(cluster => item.branchId === cluster.name)?.branches;

            if (!branches) {
                declusteredItems.push(item);

                return;
            }

            branches.map(branch => {
                declusteredItems.push({
                    ...item,
                    branchId: branch
                });
            });
        });

        return declusteredItems;
    };

    const updateMatrixOrderItems = (): PromiseResponseData | null => {
        stopTimeout();
        if (matrixData === null) {
            return null;
        }
        let items: MatrixUpdateCartItem[] = [];
        try {
            for (const [key] of Object.entries(orderItems)) {
                if (orderItems[key].quantity === ('storedQuantity' in orderItems[key] ? orderItems[key].storedQuantity : orderItems[key].quantity)) {
                    continue;
                }

                const updateItem: MatrixUpdateCartItem = getUpdateItem(orderItems[key], matrixData);
                items.push(updateItem);
                orderItems[key].storedQuantity = orderItems[key].quantity;

                if (orderItems[key].quantity === 0) {
                    delete orderItems[key];
                }
            }
        } catch (e: any) {
            errorHandler(e);

            return null;
        }

        items = declusterBranchItems(items);
        updatePromise = updateMatrix(matrixData, items, storedCartUuid);
        updatePromise!.then(backendUpdatedHandler).catch((err: any) => {
            errorHandler(err);
            $emitter.$emit('update:updatingQuantities', false);
        });

        return updatePromise;
    };

    const compareGtinQuantities = <MDataType extends ItemWithDeliveryDate>(
        cluster: BranchCluster,
        itemsForDate: MDataType[]
    ): boolean => {
        const gtinQuantityInCluster = cluster.branches.map(branchId => itemsForDate.filter(item => item.branchId === branchId)
            .reduce((acc, currentItem) => {
                acc[currentItem.gtin] = (acc[currentItem.gtin] || 0) + (currentItem.quantity || 0);

                return acc;
            }, {} as Record<string, number>));

        let quantitiesValid = true;
        gtinQuantityInCluster.forEach((current, index) => {
            gtinQuantityInCluster.forEach((compare, compareIndex) => {
                if (index !== compareIndex) {
                    Object.keys(current).forEach(gtin => {
                        if (current[gtin] !== compare[gtin]) {
                            quantitiesValid = false;
                        }
                    });
                }
            });
        });

        return quantitiesValid;
    };

    const getAllGtinsForAllBranchesAndCompare = <MDataType extends ItemWithDeliveryDate>(
        cluster: BranchCluster,
        itemsForDate: MDataType[]
    ): boolean => {
        const gtinsForCheckedCluster = cluster.branches.map(branchId => itemsForDate.filter(item => item.branchId === branchId));

        let gtinsValid = true;

        gtinsForCheckedCluster.map((array, index) => {
            gtinsForCheckedCluster.map((arrayToCompare, compareIndex) => {
                if (index !== compareIndex) {
                    array.map(item => {
                        if (!arrayToCompare.some(itemToCompare => itemToCompare.gtin === item.gtin)) {
                            gtinsValid = false;
                        }
                    });
                }
            });
        });

        return gtinsValid;
    };

    const mapBranchCluster = <MDataType extends ItemWithDeliveryDate>(
        items: MDataType[],
        branchClusters: BranchCluster[]
    ): MDataType[] | false => {
        const deliveryDates = Array.from(new Set(items.map(item => item.deliveryDate)));
        const clusteredItems: MDataType[] = [];

        const isError = deliveryDates.some(date => {
            const itemsForDate = items.filter(item => item.deliveryDate === date && item?.quantity !== 0);

            return branchClusters.some(cluster => {
                if (!getAllGtinsForAllBranchesAndCompare(cluster, itemsForDate)) {
                    return true;
                }

                if (!getAllGtinsForAllBranchesAndCompare(cluster, itemsForDate) ||
                    !compareGtinQuantities(cluster, itemsForDate)) {
                    return true;
                }

                const representativeItem = itemsForDate.filter(item => cluster.branches.includes(item.branchId));

                if (representativeItem) {
                    representativeItem.some(item => {
                        clusteredItems.push({
                            ...item,
                            branchId: cluster.name
                        });
                    });
                }

                return false;
            });
        });

        if (isError) {
            return false;
        }

        return clusteredItems.length > 0 ? clusteredItems : items;
    };

    const validateBranchesInSelectedBranchCluster = <VDataType extends ItemWithDeliveryDate>(
        items: VDataType[]
    ): VDataType[] => {
        if (!getClusterState()) {
            return items;
        }

        const branchCluster = getBranchCluster()?.clusters;

        if (!branchCluster || !items.length) {
            setClusterState(true);

            return items;
        }

        const clusteredQuantities = mapBranchCluster<VDataType>(items, branchCluster);

        if (clusteredQuantities) {
            setClusterState(true);

            return clusteredQuantities;
        }

        setClusterState(false);

        return items;
    };

    const updateCartData = (response: MatrixData) => {
        // Force immediate update if update from old matrix still pending
        if (timeout !== undefined) {
            stopTimeout();
            updateMatrixOrderItems();
        }

        orderItems = {};
        moduleItems = {};
        matrixData = response;

        const filterAndMapItems = (items: MatrixStoredOrderItem[], targetContainer: any, modules = false) => items
            .filter(item => response.concreteProductsByGtin?.[item.gtin])
            .forEach(item => {
                const concreteProduct = response?.concreteProductsByGtin?.[item?.gtin];
                item.colorKey = (item?.gtin && concreteProduct) ? concreteProduct.colorKey : '-';
                item.storedQuantity = item.quantity ?? 0;
                targetContainer[modules ? createModuleKey(item) : createCartKey(item)] = item;
            });

        let items = matrixData.items.orderItems;
        let modules = matrixData.items.moduleItems;

        items = validateBranchesInSelectedBranchCluster<MatrixOrderItem>(items);
        modules = validateBranchesInSelectedBranchCluster<MatrixModuleItem>(modules);

        filterAndMapItems(items, orderItems);
        filterAndMapItems(modules, moduleItems, true);
    };

    const onBeforeUnload = () => {
        updateMatrixOrderItems();
        stopTimeout();
    };

    const updateMatrixDataOrderItems = () => {
        if (!matrixData) {
            return;
        }

        // @ts-ignore
        matrixData.items.orderItems = declusterBranchItems(
            Object.values(orderItems).filter(item => item.quantity !== 0)
        );
    };

    const updateMatrixOrderItemsDelayed = (): Promise<any> | null => {
        stopTimeout();

        if (needsUpdate()) {
            window.addEventListener('beforeunload', onBeforeUnload);

            return new Promise(resolve => {
                storedCartUuid = cartUuid.value ?? cartsStore.activeCart?.id;
                if (minQuantitiesAchieved.value) {
                    timeout = window.setTimeout(() => {
                        quantitiesSubmitted.value = false;
                        updateMatrixOrderItems()?.then(resolve);
                    }, updateDelay);
                }
                updateMatrixDataOrderItems();
            });
        }

        return null;
    };

    const updateMatrixOrderItemsBranchCluster = async(branchClusterTemplate: BranchClusterState['branchClusterTemplate'] | null) => {
        const branchClusterId = branchClusterTemplate?.branchClusterId;
        storedCartUuid = cartUuid.value ?? cartsStore.activeCart?.id;

        if (!branchClusterId) {
            matrixData.items.branchCluster = '-1';

            updatePromise = updateMatrix(matrixData, [], storedCartUuid);
            updatePromise!.then(backendUpdatedHandler).catch((err: any) => {
                errorHandler(err);
                $emitter.$emit('update:updatingQuantities', false);
            });

            return;
        }

        if (matrixData.items.branchCluster !== branchClusterId.toString()) {
            await nextTick(() => {
                matrixData.items.branchCluster = branchClusterId.toString();
            });

            updatePromise = updateMatrix(matrixData, [], storedCartUuid);
            updatePromise!.then(backendUpdatedHandler).catch((err: any) => {
                errorHandler(err);
                $emitter.$emit('update:updatingQuantities', false);
            });
            setClusterState(true);
        }
    };

    const updateMatrixOrderItemQuantity = (
        params: MatrixStoredOrderItem,
        matrixResponseData?: EnrichedMatrixDataSet,
        groupByValue?: boolean
    ) => {
        const key = createCartKey(params);
        const current = orderItems[key];
        const newItem = { ...params };
        if (current) {
            current.storedQuantity = current.quantity;
            current.quantity = newItem.quantity;
        } else if (newItem.quantity !== 0) {
            newItem.storedQuantity = 0;
            orderItems[key] = newItem;
        }

        if (matrixResponseData) {
            matrixData.items.orderItems = Object.keys(orderItems).map(itemKey => orderItems[itemKey] as MatrixOrderItem);
            const result = getCartQuantitiesByBranches();
            if (!checkMinQuantities(matrixResponseData, result, groupByValue ?? false)) {
                return Promise<null>;
            }
        }

        return updateMatrixOrderItemsDelayed();
    };

    const resetMatrixOrderItemQuantities = (
        date?: string,
        matrixResponseData?: EnrichedMatrixDataSet,
        groupByValue?: boolean
    ): PromiseResponseData | null => {
        if (matrixData === null) {
            return null;
        }

        !date ? orderItems = {} : null;
        if (!date) {
            storedCartUuid = cartUuid.value ?? cartsStore.activeCart?.id;
            updatePromise = clearMatrix(matrixData, storedCartUuid);
            updatePromise!.then(backendUpdatedHandler).catch((err: any) => errorHandler(err));
            matrixData.items.orderItems = [];
            orderItems = {};
        } else {
            Object.values(orderItems).filter((orderItem => (orderItem.deliveryDate === date)
            )).map((orderItem) => {
                updatePromise = updateMatrixOrderItemQuantity({
                    ...orderItem,
                    quantity: 0
                }, matrixResponseData, groupByValue) as Promise<any>;
            });
        }

        return updatePromise;
    };

    const getCartQuantity = (deliveryDate: string, branchId: string, gtin: string): number => {
        const key = createCartKey({ gtin: gtin, branchId: branchId, deliveryDate: deliveryDate });

        return orderItems[key]?.quantity ?? 0;
    };

    const getCartModuleQuantity = (activeDateIndex: number, branchId: string, gtin: string): number | null => {
        if (activeDateIndex !== 0) {
            return null;
        }

        const key = createModuleKey({ gtin: gtin, branchId: branchId });

        return moduleItems[key]?.quantity ?? null;
    };

    const getModuleItemsMinQuantities = (gtin: string, branchId: string): number => Object.values(moduleItems).reduce((totalQuantity, item) => {
        const quantity = item?.quantity || 0;
        const colorKeyToValidate = getColorIdByGtin(gtin);
        const colorKeyModuleItem = getColorIdByGtin(item.gtin);

        if (colorKeyToValidate === colorKeyModuleItem && branchId === item.branchId) {
            return totalQuantity + quantity;
        }

        return totalQuantity;
    }, 0);

    const getCartQuantitiesForDate = (date: string): MatrixStoredOrderItem[] => Object.values(orderItems).filter(
        item => item.deliveryDate === date
    );

    const validateModuleItemQuantityByBranchAndColor = (orderItemsAfterUpdate: OrderItemsAfterUpdate): boolean => {
        let validQuantity = true;

        for (const { branchId, gtin } of Object.values(orderItems)) {
            const deleteKey = createModuleValidationKey(branchId, getColorIdByGtin(gtin));
            const minQuantity = getModuleItemsMinQuantities(gtin, branchId);

            if (orderItemsAfterUpdate[deleteKey] < minQuantity) {
                validQuantity = false;
                break;
            }
        }

        return validQuantity;
    };

    const validateModuleItemQuantity = (orderItemsToDelete: MatrixModuleItemValidation, moduleTypeArticle: boolean): boolean => {
        const isModuleItems = Boolean(Object.values(moduleItems).length);

        if (!isModuleItems) {
            return true;
        }

        const orderItemsAfterUpdate: OrderItemsAfterUpdate = {};

        if (orderItemsToDelete.items.length === 0) {
            const itemsOnDate = getCartQuantitiesForDate(orderItemsToDelete.deliveryDate);
            orderItemsToDelete.items = itemsOnDate.map(item => ({
                gtin: item.gtin,
                branchId: item.branchId,
                quantity: 0,
                colorKey: getColorIdByGtin(item.gtin)
            }));
        }

        for (const { gtin, branchId, quantity } of Object.values(orderItems)) {
            const updateKey = createModuleValidationKey(branchId, getColorIdByGtin(gtin));
            orderItemsAfterUpdate[updateKey] = (orderItemsAfterUpdate[updateKey] || 0) + (quantity || 0);
        }

        for (const { gtin, branchId, quantity } of orderItemsToDelete.items) {
            const key = createCartKey({ gtin, branchId, deliveryDate: orderItemsToDelete.deliveryDate });
            const currentQuantity = orderItems[key]?.quantity || 0;
            const deleteKey = createModuleValidationKey(branchId, getColorIdByGtin(gtin));

            orderItemsAfterUpdate[deleteKey] = (orderItemsAfterUpdate[deleteKey] || 0) + (currentQuantity * -1) + (quantity || 0);
        }
        let validArticleQuantities: boolean = true;

        if (moduleTypeArticle) {
            validArticleQuantities = orderItemsToDelete.items.every(item => {
                const moduleItem = Object.values(moduleItems).find(module => item.gtin === module.gtin && item.branchId === module.branchId);

                return !moduleItem || item.quantity >= moduleItem.quantity;
            });
        }

        return validateModuleItemQuantityByBranchAndColor(orderItemsAfterUpdate) && validArticleQuantities;
    };

    const calculateAdjustedQuantity = (quantity: number | undefined, gtin: string) => {
        if (quantity === undefined) {
            return undefined;
        }

        const packagingUnit = matrixData?.concreteProductsByGtin?.[gtin]?.packagingUnit ?? 1;
        const mod = quantity % packagingUnit;

        let adjustedQuantity = quantity;

        if (mod !== 0) {
            adjustedQuantity += packagingUnit - mod;
        }

        adjustedQuantity = Math.max(0, adjustedQuantity);

        return adjustedQuantity;
    };

    return {
        updateUserflowToggleState,
        updateNetPricesChangedState,
        updateMatrixOrderItemsBranchCluster,
        getModuleItemsMinQuantities,
        calculateAdjustedQuantity,
        updateMatrixOrderItems,
        updateCartData,
        matrixClosed,
        getCartQuantity,
        getCartQuantitiesForDate,
        getCartModuleQuantity,
        validateModuleItemQuantity,
        getCartQuantitiesByBranches,
        updateMatrixOrderItemQuantity,
        resetMatrixOrderItemQuantities,
        declusterBranchItems,
        quantitiesSubmitted
    };
}
