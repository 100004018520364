import type {
    MatrixHeaderSection,
    MatrixPricesStorePriceDataArrayType,
    MatrixPricesStorePriceDataType,
    MatrixPricesStorePriceListType
} from '~/composables/types/matrix/useMatrixContentDataTypes';
import type { MatrixOrderItem } from '~/composables/types/api/cartConditions/matrix';

const PURCHASE_PRICE = 'PURCHASE';
type DEFAULT_PRICE = 'DEFAULT';

type PriceTypes = typeof PURCHASE_PRICE | DEFAULT_PRICE;

export const useMatrixPricesStore = defineStore('matrixPrices', {
    state: () => ({
        defaultPrices: [] as MatrixPricesStorePriceListType[],
        purchasePrices: [] as MatrixPricesStorePriceListType[],
        priceDataArray: [] as MatrixPricesStorePriceDataArrayType,
        sections: [] as MatrixHeaderSection[],
        activeDate: '',
        initialized: false,
        cartItems: [] as MatrixOrderItem[],
    }),

    actions: {
        initializeStore() {
            this.defaultPrices = this.priceDataArray.map(pd => pd.DEFAULT).filter(Boolean);
            this.purchasePrices = this.priceDataArray.map(pd => pd.PURCHASE).filter(Boolean);
            this.initialized = true;
        },
        setActiveMatrixDate(date: string) {
            this.activeDate = date;
        },
        mapPrices(concreteProducts: {
            priceDataByPriceType: {
                DEFAULT?: MatrixPricesStorePriceListType;
                PURCHASE?: MatrixPricesStorePriceListType;
            };
            gtin: string;
        }[]) {
            this.priceDataArray = concreteProducts.map(product => {
                const priceData: MatrixPricesStorePriceDataType = {
                    DEFAULT: {
                        intersportPriceType: 0,
                        intersportPriceList: []
                    },
                    PURCHASE: {
                        intersportPriceType: 0,
                        intersportPriceList: []
                    }
                };

                if (product.priceDataByPriceType) {
                    if (product.priceDataByPriceType.DEFAULT) {
                        priceData.DEFAULT = {
                            intersportPriceType: product.priceDataByPriceType.DEFAULT.intersportPriceType,
                            intersportPriceList: product.priceDataByPriceType.DEFAULT.intersportPriceList.map(price => ({
                                ...price,
                                gtin: product.gtin
                            }))
                        };
                    }

                    if (product.priceDataByPriceType.PURCHASE) {
                        priceData.PURCHASE = {
                            intersportPriceType: product.priceDataByPriceType.PURCHASE.intersportPriceType,
                            intersportPriceList: product.priceDataByPriceType.PURCHASE.intersportPriceList.map(price => ({
                                ...price,
                                gtin: product.gtin
                            }))
                        };
                    }
                }

                return priceData;
            });
        },
        setSectionsForInfoData(sections: MatrixHeaderSection[]) {
            this.sections = sections;
        },
        getPriceForCellByGtin(gtin: string, priceType: PriceTypes, useDate?: string): number | null {
            const priceList = priceType === PURCHASE_PRICE ? this.purchasePrices : this.defaultPrices;

            const matchingPriceList = priceList.find(
                pl => pl.intersportPriceList.some(
                    price => price.gtin === gtin
                )
            )?.intersportPriceList || [];

            const matchingPrice = matchingPriceList.find(price => {
                const priceValidFrom = new Date(price.validFrom);
                const priceValidTo = new Date(price.validTo);
                const activeValidDate = useDate ? new Date(useDate) : new Date(this.activeDate);

                return price.gtin === gtin &&
                    activeValidDate >= priceValidFrom &&
                    activeValidDate <= priceValidTo;
            });

            if (matchingPrice) {
                return matchingPrice.price;
            }

            return null;
        },
        getSmallestUPEPriceOfSection(rowIndex: number, sectionIndex: number): number | null {
            if (sectionIndex < 0 || sectionIndex >= this.sections.length) {
                return null;
            }

            const section = this.sections[sectionIndex];
            const cell = section.columns?.[0]?.cells?.[rowIndex];

            return this.getPriceForCellByGtin(cell.gtin, PURCHASE_PRICE);
        },
        getUPEPriceForCell(gtin: string, defaultPrice: number, useDate?: string): number {
            return this.getPriceForCellByGtin(gtin, PURCHASE_PRICE, useDate) || defaultPrice;
        },
        setCartOrderItems(items: MatrixOrderItem[]) {
            this.cartItems = items;
            const { $emitter } = useNuxtApp();
            $emitter.$emit('updateTotalSums');
        },
        getEkSinglePriceForGtin(gtin: string) {
            const item = this.cartItems.find(item => item.gtin === gtin);

            return item ? item.finalPriceSingle : null;
        },
    },
});
